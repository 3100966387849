import React from "react";
import Footer from "../../Components/Footer";
import style from "./Style.module.css";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
import { Container } from "react-grid-system";

const gradeOption = [
  { label: "A", value: "10" },
  { label: "B+", value: "8" },
  { label: "B", value: "6" },
  { label: "C+", value: "4" },
  { label: "C", value: "2" },
  { label: "D", value: "0" },
];

const friendOption = [
  { label: "10 (เก่งมาก)", value: "10" },
  { label: "9", value: "9" },
  { label: "8", value: "8" },
  { label: "7", value: "7" },
  { label: "6", value: "6" },
  { label: "5 (ปานกลาง)", value: "5" },
  { label: "4", value: "4" },
  { label: "3", value: "3" },
  { label: "2", value: "2" },
  { label: "1", value: "1" },
  { label: "0 (ไม่เก่งเลย)", value: "0" },
];

const subjOption = [
  { label: "วิชาเฉพาะทาง (Ex. Finance, Accounting)", value: "10" },
  { label: "วิชากลางมหาวิทยาลัย (Ex. TU101)", value: "8+" },
  { label: "วิชากลางคณะ (Ex. Calculus)", value: "8" },
  { label: "วิชาเลือกอิสระอื่นๆ ที่ไม่เกี่ยวกับสาขาวิชาที่เรียน", value: "7" },
];

const typeOption = [
  {
    label: "Type 1: Full (ละเอียดมาก ไม่จำเป็นต้องอ่าน Text เพิ่มเติม)",
    value: "20+",
  },
  { label: "Type 2: Cheat Sheet ก่อนสอบ", value: "20" },
  {
    label: "Type 3: เหมือน Type 1 แต่ย่อยมาแล้วจับเฉพาะประเด็นสำคัญ",
    value: "15",
  },
  { label: "Type 4: รวมสูตรคำนวณ + คำอธิบายสูตร", value: "13" },
  { label: "Type 5: รวมคำศัพท์", value: "10+" },
  { label: "Type 6: แบบฝึกหัด", value: "10" },
];

const attoutOption = [
  { label: "100%", value: "5" },
  { label: "80%", value: "4" },
  { label: "60%", value: "2" },
  { label: "50%", value: "0" },
];

const timeOption = [
  { label: "60 นาที", value: "10" },
  { label: "55 นาที", value: "9" },
  { label: "50 นาที", value: "8" },
  { label: "45 นาที", value: "7" },
  { label: "40 นาที", value: "6" },
  { label: "35 นาที", value: "5" },
  { label: "30 นาที", value: "4" },
  { label: "25 นาที", value: "3" },
  { label: "20 นาที", value: "2" },
  { label: "15 นาที", value: "1" },
  { label: "10 นาที", value: "0" },
];

const contentOption = [
  { label: "ครบมากที่สุด", value: "11" },
  { label: "ครบมาก", value: "8" },
  { label: "ครบปานกลาง", value: "6" },
  { label: "ไม่ค่อยครบ", value: "4" },
  { label: "ไม่ครบแน่ๆ", value: "2" },
];

const complexityOption = [
  {
    label: "9 (กลั่นกรองมาอย่างดี มีการสรุปให้เป็นภาษาที่เข้าใจง่าย)",
    value: "9",
  },
  { label: "8", value: "8" },
  { label: "7", value: "7" },
  { label: "6", value: "6" },
  { label: "5", value: "5" },
  { label: "4", value: "4" },
  { label: "3", value: "3" },
  { label: "2", value: "2" },
  { label: "1 (ลอกลงมาเลย)", value: "1" },
];

const whitespaceOption = [
  { label: "10 (ที่ว่างน้อยมาก)", value: "10" },
  { label: "9", value: "9" },
  { label: "8", value: "8" },
  { label: "7", value: "7" },
  { label: "6", value: "6" },
  { label: "5", value: "5" },
  { label: "4", value: "4" },
  { label: "3", value: "3" },
  { label: "2", value: "2" },
  { label: "1", value: "1" },
  { label: "0 (ที่ว่างเยอะมาก)", value: "0" },
];

const creativityOption = [
  { label: "3 (สวย)", value: "3" },
  { label: "2", value: "2" },
  { label: "1 (ไม่สวยเลย)", value: "1" },
];

class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grade: "10",
      friend: "10",
      subj: "10",
      type: "20+",
      attendance: "5",
      outside: "5",
      time: "10",
      content: "11",
      complexity: "9",
      whitespace: "10",
      creativity: "3",
      page: "1",
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className={style.sheetPage}>
          <Container>
            <div className={style.container}>
              <div className={style.blankTop} />
              <div className={style.header}>ตัวช่วยตั้งราคาชีท</div>

              <div className={style.label}>1. Grade</div>
              <div className={style.explainLabel}>
                เกรดของวิชาที่คุณจะนำชีทสรุปนำมาลง หรือ
                เกรดที่คุณคาดว่าจะได้รับจากวิชาที่คุณจะนำมาลง
              </div>
              <select
                className={style["textField"]}
                value={this.state.grade}
                onChange={(event) => {
                  this.setState({ grade: event.currentTarget.value });
                }}
              >
                {this.renderOptions(gradeOption)}
              </select>

              <div className={style.label}>2. Friend perception</div>
              <div className={style.explainLabel}>
                คุณเป็นคนเรียนเก่งในสายตาของเพื่อนคุณในระดับไหน
              </div>
              <select
                className={style["textField"]}
                value={this.state.friend}
                onChange={(event) => {
                  this.setState({ friend: event.currentTarget.value });
                }}
              >
                {this.renderOptions(friendOption)}
              </select>

              <div className={style.label}>3. Subject Related</div>
              <div className={style.explainLabel}>
                วิชานี้จัดอยู่ในหมวดหมู่ใดของมหาวิทยาลัย
              </div>
              <select
                className={style["textField"]}
                value={this.state.subj}
                onChange={(event) => {
                  this.setState({ subj: event.currentTarget.value });
                }}
              >
                {this.renderOptions(subjOption)}
              </select>

              <div className={style.label}>4. Type of Lecture</div>
              <div className={style.explainLabel}>ประเภทของชีทสรุป</div>
              <select
                className={style["textField"]}
                value={this.state.type}
                onChange={(event) => {
                  this.setState({ type: event.currentTarget.value });
                }}
              >
                {this.renderOptions(typeOption)}
              </select>

              <div className={style.label}>5. Attendance</div>
              <div className={style.explainLabel}>
                คุณเข้าคลาสในห้องเยอะหรือไม่แค่ไหนอย่างไร
                <br />
                Ex. วิชาเรียนทีค้องเข้าคลาส 10 คาบ คุณเข้า 8 คาบ แสดงว่าคุณมี
                80% attendence
              </div>
              <select
                className={style["textField"]}
                value={this.state.attendance}
                onChange={(event) => {
                  this.setState({ attendance: event.currentTarget.value });
                }}
              >
                {this.renderOptions(attoutOption)}
              </select>

              <div className={style.label}>6. Outside class study</div>
              <div className={style.explainLabel}>
                คุณมีชั่วโมงการเรียนนอกคลาส / เรียนด้วยตัวเองเยอะหรือไม่
              </div>
              <select
                className={style["textField"]}
                value={this.state.outside}
                onChange={(event) => {
                  this.setState({ outside: event.currentTarget.value });
                }}
              >
                {this.renderOptions(attoutOption)}
              </select>

              <div className={style.label}>
                7. Time spent to create 1 page of lecture
              </div>
              <div className={style.explainLabel}>
                เวลาที่ใช้เขียนชีทสรุปโดยเฉลี่ยต่อ 1 หน้า
              </div>
              <select
                className={style["textField"]}
                value={this.state.time}
                onChange={(event) => {
                  this.setState({ time: event.currentTarget.value });
                }}
              >
                {this.renderOptions(timeOption)}
              </select>

              <div className={style.label}>
                8. ความครบถ้วนของเนื้อหาและ content
              </div>
              <select
                className={style["textField"]}
                value={this.state.content}
                onChange={(event) => {
                  this.setState({ content: event.currentTarget.value });
                }}
              >
                {this.renderOptions(contentOption)}
              </select>

              <div className={style.label}>9. Complexity</div>
              <div className={style.explainLabel}>
                เนื้อหากลั่นกรองมาเพียงใด / ลอกลงมาเลยหรือไม่
              </div>
              <select
                className={style["textField"]}
                value={this.state.complexity}
                onChange={(event) => {
                  this.setState({ complexity: event.currentTarget.value });
                }}
              >
                {this.renderOptions(complexityOption)}
              </select>

              <div className={style.label}>10. White Space per Page</div>
              <div className={style.explainLabel}>ที่ว่างในแต่ละหน้า</div>
              <select
                className={style["textField"]}
                value={this.state.whitespace}
                onChange={(event) => {
                  this.setState({ whitespace: event.currentTarget.value });
                }}
              >
                {this.renderOptions(whitespaceOption)}
              </select>

              <div className={style.label}>11. Creativity</div>
              <div className={style.explainLabel}>ความสวยงาม</div>
              <select
                className={style["textField"]}
                value={this.state.creativity}
                onChange={(event) => {
                  this.setState({ creativity: event.currentTarget.value });
                }}
              >
                {this.renderOptions(creativityOption)}
              </select>

              <div className={style.label}>12. จำนวนหน้า</div>
              <input
                type="text"
                className={style["textField"]}
                value={this.state.page}
                onChange={(event) => {
                  this.setState({ page: event.currentTarget.value });
                }}
              />

              <div className={style.recText}>ราคาที่แนะนำ</div>
              <div className={style.recAmount}>{this.calPrice()} บาท</div>
            </div>
          </Container>
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  toInt = (inn) => {
    try {
      return parseInt(inn.replace("+", ""));
    } catch (err) {
      return 0;
    }
  };

  calPrice = () => {
    var sum =
      this.toInt(this.state.grade) +
      this.toInt(this.state.friend) +
      this.toInt(this.state.subj) +
      this.toInt(this.state.type) +
      this.toInt(this.state.attendance) +
      this.toInt(this.state.outside) +
      this.toInt(this.state.time) +
      this.toInt(this.state.content) +
      this.toInt(this.state.complexity) +
      this.toInt(this.state.whitespace) +
      this.toInt(this.state.creativity);
    return Math.round((1 + (2 * sum) / 100) * this.toInt(this.state.page));
  };

  renderOptions = (ops) => {
    return ops.map(({ label, value }) => (
      <option label={label} value={value} key={value}>
        {label}
      </option>
    ));
  };
}

export default withRouter(withAlert()(EditSheet));
