import React from "react";
import Util from "../../Util";
import Nav from "../../Components/Nav";
import SellSheet from "../../Components/SellSheet";
import style from "./Style.module.css";
import { Container } from "react-grid-system";
import Footer from "../../Components/Footer";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";

class Sell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sheetID: "",
      data: {
        user: {
          infoEnter: true,
        },
        report: [],
        sellerData: {},
        sheets: [],
      },
      renderMoney: [],
      mapperName: {},
      mapperPrice: {},
    };
  }

  async componentDidMount() {
    var data = await Util.sendPost("/api/seller/getSellRev", {});
    if (data.success) {
      var x = [],
        map = {},
        mapPrice = {};
      await this.setState({ data: data.data });
      for (let i = 0; i < data.data.report.length; i++) {
        var n = data.data.report[i];
        n.image = 0;
        n.num = i;
        n.open = false;
        x.push(n);
      }
      for (let i = 0; i < data.data.sheets.length; i++) {
        map[data.data.sheets[i]._id] = data.data.sheets[i].name;
        mapPrice[data.data.sheets[i]._id] = data.data.sheets[i].price;
      }
      await this.setState({
        renderMoney: x,
        mapperName: map,
        mapperPrice: mapPrice,
      });
      for (let i = 0; i < data.data.report.length; i++) this.checkImage(i);
    } else this.props.history.push("/seller");
  }

  render() {
    return (
      <React.Fragment>
        <div className={style.shopPage}>
          <Nav current="seller" user={this.state.data.user} />
          <Container>
            <div className={style.blanker} />
            <div className={style.bigHeader}>รายได้</div>
            <div className={style.sellID}>
              {"รหัสผู้ขาย : " + this.state.data.user._id}
            </div>
            <hr />
            <div className={style.sellDataHead}>นามปากกา</div>
            <div className={style.sellData}>
              {this.state.data.sellerData.codeName}
            </div>
            <div className={style.sellDataHead}>ข้อมูลรับเงิน</div>
            <div className={style.sellDataend}>
              {this.state.data.sellerData.bankInfo}
            </div>
            <hr />
            <div className={style.payContainer}>{this.renderPay()}</div>
          </Container>
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  checkImage = async (num) => {
    var year = this.state.renderMoney[num].year;
    var month = this.state.renderMoney[num].month;
    var g = this.state.renderMoney;
    if (
      parseInt(year) === 2019 ||
      (parseInt(year) === 2020 && parseInt(month) < 9)
    ) {
      var im = await Util.checkReceipt(
        year,
        month,
        this.state.renderMoney[num].store,
        this.state.renderMoney[num].author
      );
      if (!im) g[num].image = 1;
      else g[num].image = im;
    } else {
      var data = await Util.sendPost("/api/seller/getslip", {
        month: parseInt(month),
        year: parseInt(year),
      });
      data = data.data;
      if (data.started && data.yours.length === 0) {
        g[num].image = 1;
      } else if (data.started && data.yours.length > 0) g[num].image = 2;
      else g[num].image = 0;
    }
    this.setState({ renderMoney: g });
  };

  renderPay = () => {
    return this.state.renderMoney.map((data) => {
      const sixty = data.total * 0.6;
      const finalPay = sixty > 1000 ? sixty * 0.97 : sixty;
      return (
        <div key={data._id}>
          <div className={style.payBox}>
            <div className={style.payHead}>
              {"เดือน " + data.month + " / " + data.year}
            </div>
            <div className={style.payMoneyHead}>
              รายได้ 60% จากยอดขาย
              {sixty >= 1000 &&
                "หลังจากหักภาษี ณ ที่จ่าย 3% เนื่องจากรายได้เกิน 1000 บาท"}
            </div>
            <div className={style.payMoney}>{finalPay.toFixed(2) + " บาท"}</div>
            {parseInt(data.year) === 2019 ||
            (parseInt(data.year) === 2020 && parseInt(data.month) < 9) ? (
              <React.Fragment>
                {data.image === 0 && <div className={style.paySlip}>...</div>}
                {data.image === 1 && (
                  <div className={style.paySlip}>อยู่ในระหว่างการโอนเงิน</div>
                )}
                {data.image !== 0 && data.image !== 1 && (
                  <div className={style.paySlip}>
                    <a href={data.image} target="noopener noreferrer">
                      คลิกเพื่อดูหลักฐานการโอนเงิน
                    </a>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {data.image === 0 && (
                  <div className={style.paySlip}>อยู่ในระหว่างการโอนเงิน</div>
                )}
                {data.image === 1 && (
                  <div className={style.paySlip}>
                    รายการโอนเงินมีปัญหา โปรดติดต่อ facebook page: more sheet
                  </div>
                )}
                {data.image === 2 && (
                  <div className={style.paySlip}>
                    <a
                      href={`slip?${data.month}&${data.year}`}
                      target="noopener noreferrer"
                    >
                      คลิกเพื่อดูหลักฐานการโอนเงิน
                    </a>
                  </div>
                )}
              </React.Fragment>
            )}
            <div
              id={data.num}
              onClick={(event) => this.toggleOpen(event.currentTarget.id)}
              className={style.payToggle}
            >
              คลิกเพื่อดูรายละเอียดการขาย
            </div>
            {data.open && (
              <div>
                <hr />
                {this.renderSheet(data.sheetList)}
                <hr />
                <div className={style.sheetHead}>
                  {"รวมยอดขายทั้งสิ้น " + data.total + " บาท"}
                </div>
                <div className={style.sheetHead}>
                  {"60% ของยอดขาย " + (data.total * 0.6).toFixed(2) + " บาท"}
                </div>
                {sixty > 1000 && (
                  <div className={style.sheetHead}>
                    {"หักภาษี ณ ที่จ่าย 3% เนื่องจากรายได้เกิน 1000 บาท เหลือ " +
                      finalPay.toFixed(2) +
                      " บาท"}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  renderSheet = (sheets) => {
    return sheets.map((data) => {
      return (
        <div key={data.sheet} className={style.sheetBox}>
          <div className={style.sheetHead}>
            {this.state.mapperName[data.sheet]}
          </div>
          <div className={style.sheetInfoCon}>
            <span>{"ขายได้ " + data.count + " ชีท"}</span>
            <span>{"รวม " + data.totalPrice + " บาท"}</span>
          </div>
        </div>
      );
    });
  };

  toggleOpen = (id) => {
    var num = parseInt(id);
    var state = this.state.renderMoney;
    state[num].open = !state[num].open;
    this.setState({ renderMoney: state });
  };

  renderVerf = () => {
    if (this.state.verfFil.length === 0)
      return <div className={style.nothing}>ไม่พบรายการ</div>;
    return this.state.verfFil.map((sheet) => {
      return <SellSheet sheet={sheet} key={sheet._id}></SellSheet>;
    });
  };

  genTermOptions = () => {
    const ops = [
      { label: "any", value: "any" },
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
    ];
    return ops.map(({ label, value }) => (
      <option
        label={label === "any" ? "ทุกเทอม" : label}
        value={value}
        key={value}
      >
        {label === "any" ? "ทุกเทอม" : label}
      </option>
    ));
  };

  genYearOptions = () => {
    var ops = [0];
    var curr = new Date().getFullYear();
    for (var i = 0; i < 10; i++) ops.push(curr - i);
    return ops.map((y) => (
      <option label={y === 0 ? "ทุกปี" : y} value={y} key={y}>
        {y === 0 ? "ทุกปี" : y}
      </option>
    ));
  };

  genSortOptions = () => {
    const ops = [
      { label: "คะแนนมากขึ้นก่อน", value: "score" },
      { label: "คนซื้อมากขึ้นก่อน", value: "sold" },
      { label: "ล่าสุดขึ้นก่อน", value: "date" },
    ];
    return ops.map(({ label, value }) => (
      <option label={label} value={value} key={value}>
        {label}
      </option>
    ));
  };

  setFilter = async (field, val) => {
    var x = this.state.filter;
    x[field] = val;
    await this.setState({ filter: x });
    await this.goFilter();
  };

  goFilter = async () => {
    var res = [];
    for (var i = 0; i < this.state.sheets.verf.length; i++) {
      var sheet = this.state.sheets.verf[i];
      if (
        this.state.filter.sem !== "any" &&
        parseInt(this.state.filter.sem) !== sheet.semester
      )
        continue;
      if (
        this.state.filter.year !== "0" &&
        parseInt(this.state.filter.year) !== sheet.year
      )
        continue;
      if (
        this.state.filter.search.trim() !== "" &&
        !sheet.name
          .toLowerCase()
          .includes(this.state.filter.search.trim().toLowerCase())
      )
        continue;
      sheet.created = new Date(sheet.created).getTime();
      res.push(sheet);
    }
    var sorter = {
      score: function (a, b) {
        return b.score - a.score;
      },
      sold: function (a, b) {
        return b.bought - a.bought;
      },
      date: function (a, b) {
        return b.created - a.created;
      },
    };
    res.sort(sorter[this.state.filter.sort]);
    await this.setState({ verfFil: res });
  };
}

export default withRouter(withAlert()(Sell));
