import React from "react";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
//import './style.css'
class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const token = this.props.location.search.substring(1);
    window.localStorage.setItem("auth", token);
    window.location.href = "/";
  }

  render() {
    return <div></div>;
  }
}

export default withRouter(withAlert()(EditSheet));
