import React from "react";
import SelectSearch from "react-select-search";
import style from "./Style.module.css";
import "./style.css";
import Util from "../../Util";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";

const yearOptions = [
  { label: "ม.4", value: -3 },
  { label: "ม.5", value: -2 },
  { label: "ม.6", value: -1 },
  { label: "จบแล้ว/อาจารย์/อื่นๆ", value: 0 },
  { label: "ปี 1", value: 1 },
  { label: "ปี 2", value: 2 },
  { label: "ปี 3", value: 3 },
  { label: "ปี 4", value: 4 },
  { label: "ปี 5", value: 5 },
  { label: "ปี 6", value: 6 },
  { label: "ปี 7", value: 7 },
  { label: "ปี 8", value: 8 },
  { label: "ปี 9", value: 9 },
  { label: "ปี 10", value: 10 },
  { label: "ปี 11", value: 11 },
  { label: "ปี 12", value: 12 },
];

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      redirect: false,
      data: {
        name: "",
        email: "",
        university: -1,
        year: 0,
        choices: {
          store: [],
          InitFac: [],
        },
        user: {
          infoEnter: true,
        },
      },
    };
  }

  async componentDidMount() {
    var data = await Util.sendPost("/api/user/getFirstInfo", {});
    if (data.success) {
      data.data.user.infoEnter = true;
      await this.setState({ data: data.data, loading: false });
      await this.setUni({ value: data.data.university.toString() });
    } else this.props.history.goBack();
  }

  render() {
    return (
      <React.Fragment>
        <div className={style.pageWrap}>
          <Nav current="" user={this.state.data.user} />
          <div className={style.topBlank} />
          <div className={style.loginBox}>
            <div className={style.header}>เราอยากรู้จักคุณ</div>
            <div className={style.label}>ชื่อ - นามสกุล</div>
            <input
              type="text"
              className={style.inputText}
              value={this.state.data.name}
              onChange={(event) => this.handleTextChange(event, "name")}
            />
            <div className={style.label}>E-mail</div>
            <input
              type="text"
              className={style.inputText}
              value={this.state.data.email}
              onChange={(event) => this.handleTextChange(event, "email")}
            />
            <div className={style.label}>สถาบัน</div>

            <SelectSearch
              options={this.genUniOptions()}
              value={this.state.data.university.toString()}
              placeholder={"เลือกสถาบัน..."}
              onChange={(value) => this.setUni(value)}
            />

            <div className={style.label}>คณะ/สายการเรียน</div>

            <SelectSearch
              options={this.genFacOptions()}
              value={this.state.data.faculty}
              placeholder={"เลือกคณะ/สายการเรียน"}
              onChange={(value) => {
                this.setFac(value);
              }}
            />
            <div className={style.label}>ชั้น/ปี</div>

            <select
              className={style.inputText}
              value={this.state.data.year}
              onChange={(event) => this.handleTextChange(event, "year")}
            >
              {yearOptions.map(({ label, value }) => (
                <option value={value} label={label} key={value}>
                  {label}
                </option>
              ))}
            </select>
            <div onClick={() => this.go()} className={style.goButton}>
              เริ่มต้นใช้งาน
            </div>
          </div>
          <div className={style.bottomBlank} />
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  handleTextChange = (event, field) => {
    var dat = this.state.data;
    dat[field] = event.currentTarget.value;
    this.setState({ data: dat });
  };

  genUniOptions = () => {
    if (this.state.data.choices.store.length === 0)
      return [{ value: "0", name: "" }];
    return this.state.data.choices.store.map(({ name, short, code }) => ({
      value: code.toString(),
      name: name[0],
    }));
  };

  setUni = async (value) => {
    var data = await Util.sendPost("/api/public/getFaculty", {
      code: parseInt(value.value),
    });
    var dat = this.state.data;
    dat.university = parseInt(value.value);
    dat.choices.InitFac = data.data;
    await this.setState({ data: dat });
  };

  setFac = async (value) => {
    var dat = this.state.data;
    dat.faculty = value.value;
    await this.setState({ data: dat });
  };

  genFacOptions = () => {
    if (this.state.data.choices.InitFac.length === 0)
      return [{ value: "", name: "" }];
    return this.state.data.choices.InitFac.map((fac) => ({
      value: fac[0],
      name: fac[0],
    }));
  };

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  go = async () => {
    if (this.state.data.name.trim().length === 0) {
      this.props.alert.show("กรุณาใส่ ชื่อ-นามสกุล", { type: "error" });
      return;
    }

    if (!this.validateEmail(this.state.data.email)) {
      this.props.alert.show("กรุณาใส่ อีเมล์ ให้ถูกต้อง", { type: "error" });
      return;
    }

    if (this.state.data.university === -1) {
      this.props.alert.show("กรุณาเลือก มหาวิทยาลัย", { type: "error" });
      return;
    }

    if (!this.state.data.faculty || !this.state.data.faculty.trim()) {
      this.props.alert.show("กรุณาเลือก คณะ / สายการเรียน", { type: "error" });
      return;
    }

    if (this.state.data.year === 0) {
      this.props.alert.show("กรุณาใส่ ชั้น/ปี", { type: "error" });
      return;
    }
    var data = await Util.sendPost("/api/user/firstInfo", {
      name: this.state.data.name,
      email: this.state.data.email,
      university: this.state.data.university,
      faculty: this.state.data.faculty,
      year: this.state.data.year,
      defaultStore: this.state.data.university,
      autoUpdatePic: true,
    });
    if (data.success) this.props.history.push("/");
    else this.props.alert.show(data.msg, { type: "error" });
  };
}

export default withRouter(withAlert()(Login));
