import React from "react";
import Util from "../../Util";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import style from "./Style.module.css";
import { Link } from "react-router-dom";
import { Container, Hidden, Visible } from "react-grid-system";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
import SellSheet from "../../Components/ShopSheet";

class Sell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {
        user: {
          infoEnter: true,
        },
        sheet: {
          store: 0,
          classInfo: "",
          author: "",
          detail: "",
          category: "",
          semester: "",
          year: "",
          created: "",
          price: 0,
          examNum: 1,
          name: "",
          otherSheet: [],
          reviews: [],
        },
      },
      dispEX: 1,
      countdown: "",
      showSample: false,
      reviews: [],
      revfil: 0,
      showFavorite: false,
      showAddFavorite: false,
      aName: "",
      fList: [],
      selectFavList: "",
    };
  }

  async componentDidMount() {
    window.history.pushState({}, "", "");
    window.onpopstate = (event) => {};
    var id =
      this.props.match.params.sheetId ||
      this.props.location.search.substring(1).split("&")[0];
    var data = await Util.sendPost("/api/public/getSheetInfo", { sheetID: id });
    if (data.success) {
      this.setState({ data: data.data, currCommentPage: 1 });
    } else this.props.history.push("/");
    this.setState({
      countdown: setInterval(() => {
        this.moveEx(1);
      }, 5000),
    });
    window.onpopstate = (event) => {
      window.close();
    };
  }

  componentWillUnmount() {
    clearInterval(this.state.countdown);
    window.onpopstate = (event) => {};
  }

  render() {
    const exam = ["", "Midterm ", "Final "];
    return (
      <div className={style.shopPage}>
        {this.state.showFavorite && !this.state.showAddFavorite && (
          <div className={style.favoriteBox}>
            <div
              className={style.fClose}
              onClick={() =>
                this.setState({ showFavorite: false, selectFavList: "" })
              }
            >
              {" "}
              X{" "}
            </div>
            <div className={style.fHeader}>เพิ่มไปยังรายการโปรด</div>
            <hr />
            <div
              className={style.aAdd}
              onClick={() => {
                this.setState({ aname: "", showAddFavorite: true });
              }}
            >
              สร้างรายการโปรดใหม่
            </div>
            <hr />
            <div className={style.fLabel}>
              คลิกเลือกรายการโปรดที่ต้องการเพิ่มชีทเข้าไป
            </div>
            <div className={style.fArea}>{this.renderFavList()}</div>
            <div className={style.bGroup}>
              <div
                className={
                  this.state.selectFavList === ""
                    ? style.fAdd + " " + style.inactive
                    : style.fAdd
                }
                onClick={
                  this.state.selectFavList === "" ? () => {} : this.submitAddFav
                }
              >
                เพิ่มเข้ารายการที่เลือก
              </div>
            </div>
          </div>
        )}
        {this.state.showAddFavorite && (
          <div className={style.addFavoriteBox}>
            <div
              className={style.fClose}
              onClick={() => this.setState({ showAddFavorite: false })}
            >
              {" "}
              X{" "}
            </div>
            <div className={style.fHeader}>เพิ่มรายการโปรดใหม่</div>
            <hr />
            <div className={style.fLabel}>
              ตั้งชื่อให้รายการของคุณ (เช่น รอซื้อใกล้สอบ)
            </div>
            <input
              className={style.addFavText}
              type="text"
              value={this.state.aname}
              onChange={(event) =>
                this.setState({ aname: event.currentTarget.value })
              }
            />
            <div className={style.bGroup}>
              <div
                className={
                  this.state.aname === ""
                    ? style.fAdd + " " + style.inactive
                    : style.fAdd
                }
                onClick={this.state.aname === "" ? () => {} : this.addFavList}
              >
                เพิ่ม
              </div>
            </div>
          </div>
        )}
        <Nav current="shop" user={this.state.data.user} />
        <div className={style.blankTop} />
        <Container>
          <Hidden xs sm>
            <div className={style.sheetInfoWrapper}>
              <div className={style.sheetLeft}>
                <img
                  className={style.sheetCover}
                  src={
                    process.env.REACT_APP_SHEET_URL +
                    this.state.data.sheet._id +
                    "/cover.jpg"
                  }
                  alt="cover"
                ></img>
                {this.state.data.user && this.state.data.user.name && (
                  <div className={style.favorite} onClick={this.showFav}>
                    เพิ่มไปยังรายการโปรด
                  </div>
                )}
                <div className={style.facebookShare} onClick={this.share}>
                  แชร์ไปที่ Facebook
                </div>
              </div>
              <div className={style.sheetRight}>
                <div className={style.sheetname}>
                  {this.state.data.sheet.name}
                </div>
                {this.state.data.sheet.store !== 32 && (
                  <div className={style.infoSemester}>
                    {exam[this.state.data.sheet.category] +
                      this.state.data.sheet.semester +
                      " / " +
                      this.state.data.sheet.year}
                  </div>
                )}
                {/*<Link to={'/profile?'+this.state.data.sheet.author}>*/}
                <div className={style.infoLink}>
                  {"By " + this.state.data.sheet.authorCode}
                </div>
                <div className={style.cusHR} />
                <div className={style.cartPanel}>
                  <div className={style.price}>
                    <div className={style.infoDataPrice}>
                      {this.state.data.sheet.price}
                    </div>
                    <div className={style.infoDataPriceCur}>บาท</div>
                  </div>
                  {!this.state.data.sheet.owned && (
                    <div
                      className={style.addCart}
                      onClick={() => this.addCart(this.state.data.sheet._id)}
                    >
                      เพิ่มลงตะกร้า
                    </div>
                  )}
                </div>
                <div className={style.boughtLight}>
                  {"ถูกซื้อแล้ว " + this.state.data.sheet.bought + " ครั้ง"}
                </div>
                <div className={style.cusHR} />
                <div className={style.infoHead}>จำนวนหน้า</div>
                <div className={style.infoData}>
                  {this.state.data.sheet.page}
                </div>
                {this.state.data.sheet.store !== 32 && (
                  <React.Fragment>
                    <div className={style.infoHead}>รายละเอียดตอนเรียน</div>
                    <div className={style.infoData}>
                      {this.nl2br(this.state.data.sheet.classInfo)}
                    </div>
                  </React.Fragment>
                )}
                <div className={style.infoHead}>รายละเอียดเพิ่มเติม</div>
                <div className={style.infoData}>
                  {this.nl2br(this.state.data.sheet.detail)}
                </div>
              </div>
            </div>
          </Hidden>
          <Visible xs sm>
            <img
              className={style.sheetCover + " " + style.mobile}
              src={
                process.env.REACT_APP_SHEET_URL +
                this.state.data.sheet._id +
                "/cover.jpg"
              }
              alt="cover"
            ></img>
            {this.state.data.user && this.state.data.user.name && (
              <div className={style.favorite} onClick={this.showFav}>
                เพิ่มไปยังรายการโปรด
              </div>
            )}
            <div className={style.facebookShare} onClick={this.share}>
              แชร์ไปที่ Facebook
            </div>
            <div className={style.sheetname}>{this.state.data.sheet.name}</div>
            <div className={style.infoSemester}>
              {exam[this.state.data.sheet.category] +
                this.state.data.sheet.semester +
                " / " +
                this.state.data.sheet.year}
            </div>
            {/*<Link to={'/profile?'+this.state.data.sheet.author}>*/}
            <div className={style.infoLink}>
              {"By " + this.state.data.sheet.authorCode}
            </div>
            <div className={style.cusHR} />
            <div className={style.cartPanel}>
              <div className={style.price}>
                <div className={style.infoDataPrice}>
                  {this.state.data.sheet.price}
                </div>
                <div className={style.infoDataPriceCur}>บาท</div>
              </div>
              {!this.state.data.sheet.owned && (
                <div
                  className={style.addCart}
                  onClick={() => this.addCart(this.state.data.sheet._id)}
                >
                  เพิ่มลงตะกร้า
                </div>
              )}
            </div>
            <div className={style.boughtLight}>
              {"ถูกซื้อแล้ว " + this.state.data.sheet.bought + " ครั้ง"}
            </div>
            <div className={style.cusHR} />
            <div className={style.infoHead}>จำนวนหน้า</div>
            <div className={style.infoData}>{this.state.data.sheet.page}</div>
            <div className={style.infoHead}>รายละเอียดตอนเรียน</div>
            <div className={style.infoData}>
              {this.nl2br(this.state.data.sheet.classInfo)}
            </div>
            <div className={style.infoHead}>รายละเอียดเพิ่มเติม</div>
            <div className={style.infoData}>
              {this.nl2br(this.state.data.sheet.detail)}
            </div>
          </Visible>
          <div className={style.cusHR} />
          {!this.state.data.sheet.owned ? (
            <React.Fragment>
              <div className={style.headerExam}>
                รูปตัวอย่างชีท (กดที่รูปเพื่อขยาย)
              </div>
              <div className={style.examContainer}>
                <div className={style.leftbutt} onClick={() => this.moveEx(-1)}>
                  {"<"}
                </div>
                <div
                  className={style.examDisp}
                  onClick={() => this.toggleBig()}
                >
                  {this.renderSmallImg()}
                </div>
                <div className={style.rightbutt} onClick={() => this.moveEx(1)}>
                  {">"}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className={style.headerExam}>
                คุณเป็นเจ้าของชีทนี้อยู่แล้ว
              </div>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={"/user/readSheet?" + this.state.data.sheet._id}
              >
                <div className={style.openSheet}>อ่านชีทเลย</div>
              </Link>
            </React.Fragment>
          )}
          <div className={style.cusHR} />
          <div className={style.headerExamNoBot}>Comment จากผู้ซื้อ</div>
          <div className={style.headerExamSmall}>
            สามารถเพิ่มได้จากหน้า "ชีทของฉัน" หากคุณซื้อชีทไปแล้ว
          </div>
          {this.state.data.sheet.reviews.length > 0 ? (
            <div className={style.commentContainer}>
              <div className={style.commentControl}>
                <div className={style.ccc} onClick={this.minusoneCommentPage}>
                  {"<"}
                </div>
                <div>
                  หน้าที่ {this.state.currCommentPage} /{" "}
                  {Math.floor((this.state.data.sheet.reviews.length - 1) / 5) +
                    1}
                </div>
                <div className={style.ccc} onClick={this.plusoneCommentPage}>
                  {">"}
                </div>
              </div>
              <div className={style.commentContainer}>
                {this.renderComments()}
              </div>
            </div>
          ) : (
            <div className={style.nocomment}>ยังไม่มี Comment</div>
          )}
          <div className={style.cusHR} />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/moresheetco/"
          >
            <button className={style.picbutt}>
              <img
                src={process.env.REACT_APP_LOGO_URL + "testad.jpg"}
                className={style.testad}
                alt=""
              />
            </button>
          </a>
          {this.state.data.sheet.otherSheet.length > 0 && (
            <React.Fragment>
              <div className={style.cusHR} />
              <div className={style.headerExam}>
                {"ชีทอื่น ๆ จาก " + this.state.data.sheet.authorCode}
              </div>
              <div className={style.sheetWrapper}>{this.renderStuff()}</div>
            </React.Fragment>
          )}
          {this.state.showSample && (
            <div className={style.examContainerBig}>
              <div className={style.closeBig} onClick={() => this.toggleBig()}>
                X
              </div>
              <div
                className={style.leftbuttBig}
                onClick={() => this.moveEx(-1)}
              >
                {"<"}
              </div>
              <div className={style.examDispBig}>{this.renderBigImg()}</div>
              <div
                className={style.rightbuttBig}
                onClick={() => this.moveEx(1)}
              >
                {">"}
              </div>
            </div>
          )}
          <div className={style.cusHR} />
        </Container>
        <Footer />
      </div>
    );
  }

  plusoneCommentPage = () => {
    var maxpage =
      Math.floor((this.state.data.sheet.reviews.length - 1) / 5) + 1;
    if (this.state.currCommentPage >= maxpage) return;
    var newVal = this.state.currCommentPage + 1;
    this.setState({ currCommentPage: newVal });
  };

  minusoneCommentPage = () => {
    if (this.state.currCommentPage <= 1) return;
    var newVal = this.state.currCommentPage - 1;
    this.setState({ currCommentPage: newVal });
  };

  renderComments = () => {
    var start = (this.state.currCommentPage - 1) * 5;
    var end = this.state.currCommentPage * 5;
    var i;
    var res = [];
    for (i = start; i < end && i < this.state.data.sheet.reviews.length; i++) {
      var x = new Date(this.state.data.sheet.reviews[i].created);
      res.push(
        <div
          key={this.state.data.sheet.reviews[i]._id + i.toString()}
          className={style.commentBox}
        >
          <div className={style.rDate}>
            ผู้ซื้อ ณ วันที่{" "}
            {`${this.padder(x.getDate())}/${this.padder(
              x.getMonth() + 1
            )}/${this.padder(x.getFullYear())} ${this.padder(
              x.getHours()
            )}:${this.padder(x.getMinutes())}`}
          </div>
          <div className={style.rmessage}>
            {this.nl2br(this.state.data.sheet.reviews[i].message)}
          </div>
        </div>
      );
    }
    return <React.Fragment>{res}</React.Fragment>;
  };

  padder = (val) => {
    if (val < 10) val = "0" + val.toString();
    return val;
  };

  toggleBig = () => {
    this.setState({ showSample: !this.state.showSample });
  };

  renderSmallImg = () => {
    var out = [];
    for (var i = 1; i <= this.state.data.sheet.examNum; i++) out.push(i);
    return out.map((item, key) => {
      return (
        <div
          className={
            style.imgDisp +
            (this.state.dispEX > item ? " " + style.imgGone : "")
          }
          key={item}
          style={{
            background:
              "#000000 url(" +
              process.env.REACT_APP_SHEET_URL +
              this.state.data.sheet._id +
              "/ex" +
              item +
              ".jpg) center center / contain no-repeat",
            zIndex: 100 - item,
          }}
        ></div>
      );
    });
  };

  renderBigImg = () => {
    var out = [];
    for (var i = 1; i <= this.state.data.sheet.examNum; i++) out.push(i);
    return out.map((item, key) => {
      return (
        <div
          className={
            style.imgDispBig +
            (this.state.dispEX > item ? " " + style.imgGone : "")
          }
          key={item}
          style={{
            background:
              "#000000 url(" +
              process.env.REACT_APP_SHEET_URL +
              this.state.data.sheet._id +
              "/ex" +
              item +
              ".jpg) center center / contain no-repeat",
            zIndex: 100 - item,
          }}
        ></div>
      );
    });
  };

  nl2br = (text) => {
    return text.split("\n").map((item, key) => {
      return (
        <React.Fragment key={key}>
          {item}
          <br />
        </React.Fragment>
      );
    });
  };

  moveEx = (off) => {
    var dat = this.state.dispEX;
    dat += off;
    if (dat < 1) dat = this.state.data.sheet.examNum;
    else if (dat > this.state.data.sheet.examNum) dat = 1;
    this.setState({ dispEX: dat });
  };

  addCart = async (id) => {
    var data = await Util.sendPost("/api/public/ownSheet", { id });
    if (data.success) {
      var res = await Util.addCart(id);
      if (res)
        this.props.alert.show("เพิ่มชีทลงตะกร้าสำเร็จ", { type: "success" });
      else this.props.alert.show("มีชีทนี้ในตะกร้าอยู่แล้ว", { type: "error" });
    } else
      this.props.alert.show("คุณเป็นเจ้าของชีทนี้อยู่แล้ว", { type: "error" });
  };

  scoreText = (score, reviewer) => {
    if (reviewer === 0) return "ไม่มีคะแนน";
    var real = score * 1.0;
    return real.toFixed(2);
  };

  renderStuff = () => {
    return this.state.data.sheet.otherSheet.map((sheet) => {
      return (
        <SellSheet
          sheet={sheet}
          key={sheet._id}
          store={{}}
          addCart={this.addCart}
        ></SellSheet>
      );
    });
  };

  share = () => {
    window.FB.ui(
      {
        display: "popup",
        method: "share",
        href: window.location.href,
      },
      function (response) {}
    );
  };

  showFav = async () => {
    var data = await Util.sendPost("/api/user/getFavoriteList");
    if (data.success) {
      await this.setState({ showFavorite: true, fList: data.data.list });
    } else {
      this.props.alert.show("พบปัญหา โปรดลองอีกครั้งภายหลัง", {
        type: "error",
      });
    }
  };

  addFavList = async () => {
    var data = await Util.sendPost("/api/user/addFavorite", {
      name: this.state.aname,
    });
    if (data.success) {
      this.props.alert.show("เพิ่มรายการใหม่สำเร็จ", { type: "success" });
      await this.setState({ showAddFavorite: false, aname: "" });
      this.showFav();
    } else {
      this.props.alert.show(data.msg[0], { type: "error" });
    }
  };

  renderFavList = () => {
    if (this.state.fList.length === 0) {
      return <div className={style.fNothing}>ไม่พบรายการที่เคยสร้างไว้</div>;
    }
    return this.state.fList.map((list) => (
      <React.Fragment key={list._id}>
        <div
          onClick={(event) =>
            this.setState({ selectFavList: event.currentTarget.id })
          }
          className={
            this.state.selectFavList === list._id
              ? style.listBox + " " + style.active
              : style.listBox
          }
          id={list._id}
        >
          <div
            className={
              this.state.selectFavList === list._id
                ? style.listCheck + " " + style.active
                : style.listCheck
            }
          />
          <div className={style.listName}>{list.name}</div>
        </div>
      </React.Fragment>
    ));
  };

  submitAddFav = async () => {
    var data = await Util.sendPost("/api/user/addSheetFavorite", {
      id: this.state.selectFavList,
      sheet: this.state.data.sheet._id,
    });
    if (data.success) {
      this.props.alert.show(
        "เพิ่มสำเร็จ สามารถจัดการรายการได้จากเมนูใต้ชื่อ รายการโปรด",
        { type: "success" }
      );
      await this.setState({ showFavorite: false, selectFavList: "" });
    } else {
      this.props.alert.show(data.msg[0], { type: "error" });
    }
  };
}

export default withRouter(withAlert()(Sell));
