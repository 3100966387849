import React from "react";
import { Container, Visible, Hidden } from "react-grid-system";
import { Link } from "react-router-dom";
import style from "./Style.module.css";

const menus = [
  { match: "home", to: "/", label: "หน้าแรก" },
  { match: "shop", to: "/shop", label: "ซื้อชีท" },
  { match: "seller", to: "/seller", label: "ขายชีท" },
  { match: "about", to: "/faq", label: "FAQ" },
];

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={style.footer}>
        <Container>
          <div className={style.allContainer}>
            <div className={style.leftContainer}>
              <div className={style.logo}>moresheet</div>
              <div className={style.copyRight}>
                Copyright © {new Date().getFullYear()} บริษัท มอร์ เซอร์วิสเซส
                จํากัด
              </div>
              <div className={style.copyRight}>moresheet.hq@gmail.com</div>
            </div>
            <Visible xs>
              <div className={style.rightContainerSmall}>
                {
                  <div className={style.menuContainerSmall}>
                    {menus.map((menu) => {
                      return (
                        <Link to={menu.to} key={menu.match}>
                          <div className={style.footerMenuSmall}>
                            {menu.label}
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                }
              </div>
            </Visible>
            <Hidden xs>
              <div className={style.rightContainer}>
                {
                  <div className={style.menuContainer}>
                    {menus.map((menu) => {
                      return (
                        <Link to={menu.to} key={menu.match}>
                          <div className={style.footerMenu}>{menu.label}</div>
                        </Link>
                      );
                    })}
                  </div>
                }
                <div className={style.detailFooter}>
                  แหล่งรวมชีทสรุปสำหรับวิชาต่างๆ จากมหาวิทยาลัยทั่วประเทศ
                  <br />
                  สำหรับการติวสอบ และ การทบกวนในรายวิชานั้นๆ
                </div>
              </div>
            </Hidden>
          </div>
          <Visible xs>
            <div className={style.detailFooterSmall}>
              แหล่งรวมชีทสรุปสำหรับวิชาต่างๆ จากมหาวิทยาลัยทั่วประเทศ
              <br />
              สำหรับการติวสอบ และ การทบกวนในรายวิชานั้นๆ
            </div>
          </Visible>
        </Container>
      </div>
    );
  }
}

export default Footer;
