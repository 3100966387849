import React from "react";
import { Redirect } from "react-router-dom";
import Util from "../../Util";
import Nav from "../../Components/Nav";
import UnRegForm from "../../Components/UnRegForm";
import style from "./Style.module.css";
import text from "./text";

class Sell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirectMysheet: false,
      redirectLogin: false,
      data: {
        user: {
          infoEnter: true,
        },
      },
    };
  }

  async componentDidMount() {
    var data = await Util.sendPost("/api/public/getSellerRegister", {});
    await this.setState({ data: data.data });
    if (!data.data.user) await this.setState({ redirectLogin: true });
    else if (data.data.user.seller)
      await this.setState({ redirectMysheet: true });
  }

  render() {
    return (
      <div className={style.shopPage}>
        {this.state.redirectMysheet && <Redirect to="/seller/mysheet" />}
        {this.state.redirectLogin && <Redirect to="/login" />}
        <UnRegForm register={this.register} />
        <Nav current="seller" user={this.state.data.user} />
      </div>
    );
  }

  register = async (data) => {
    const lang = Util.getLang();
    data.bankInfo = data.bankName.trim() + "," + data.bankNumber.trim();
    var data2 = await Util.sendPost("/api/user/registerSeller", data);
    var func = async () => {
      await this.setState({ redirectMysheet: true });
    };
    if (data2.success) Util.alertSuccess(text[lang].success, func);
    else Util.alertError(data2.msg[lang]);
  };
}

export default Sell;
