import React from "react";
import { Redirect } from "react-router-dom";
import SelectSearch from "react-select-search";
import Util from "../../Util";
import Report from "../../Components/Report";
import style from "./Style.module.css";
import { Container } from "react-grid-system";

class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      data: {
        user: {},
        stores: [],
      },
      active: [],
      passive: [],
      store: -2,
      loading: false,
    };
  }

  async componentDidMount() {
    var data = await Util.sendPost("/api/moresheetStaff/getReportMeta", {});
    if (!data.success) await this.setState({ redirect: true });
    await this.setState({ data: data.data });
    await this.setState({ store: data.data.stores[0].code });
    await this.loadReport(this.state.store);
  }

  render() {
    return (
      <div className={style.sheetPage}>
        {this.state.redirect && <Redirect to="/staff/" />}
        <Container>
          <div className={style.header}>Report</div>
          <div className={style.explain}>เลือกมหาวิทยาลัยเพื่อดูข้อมูล</div>
          <hr />
          <SelectSearch
            className="select-search-box-verifySheet"
            options={this.genUniOptions()}
            value={this.state.store.toString()}
            onChange={(value) => this.setUni(value)}
          />
          <hr />
          <h2>ยังไม่ถูกจัดการ</h2>
          <hr />
          {this.renderAC()}
          <h2>จัดการแล้วในรอบ 30 วันที่ผ่านมา</h2>
          <hr />
          {this.renderPA()}
        </Container>
      </div>
    );
  }

  takeAction = async (id, action) => {
    var data = await Util.sendPost("/api/moresheetStaff/actionReport", {
      id,
      action,
    });
    if (data.success) this.loadReport(this.state.store);
    else alert(data.msg[0]);
  };

  renderAC = () => {
    if (this.state.active.length === 0) return <p>ไม่มีรายการ</p>;
    return this.state.active.map((rep) => {
      return <Report takeAction={this.takeAction} report={rep} key={rep._id} />;
    });
  };

  renderPA = () => {
    if (this.state.passive.length === 0) return <p>ไม่มีรายการ</p>;
    return this.state.passive.map((rep) => {
      return <Report takeAction={this.takeAction} report={rep} key={rep._id} />;
    });
  };

  genUniOptions = () => {
    if (this.state.data.stores.length === 0) return [{ value: "0", name: "" }];
    return this.state.data.stores.map(({ name, code }) => ({
      value: code.toString(),
      name: name.join(" - "),
    }));
  };

  setUni = async (value) => {
    await this.setState({ store: parseInt(value.value) });
    await this.loadReport(this.state.store);
  };

  loadReport = async (store) => {
    var data = await Util.sendPost("/api/moresheetStaff/getReport", { store });
    if (data.success)
      this.setState({ active: data.data.active, passive: data.data.passive });
    else alert(data.msg[0]);
  };
}

export default EditSheet;
