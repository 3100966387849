import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function BAHTTEXT(a, b) {
  if (
    (void 0 === b && (b = "บาทถ้วน"),
    (a = a || 0),
    (a = a.toString().replace(/[, ]/g, "")),
    isNaN(a) || Math.round(100 * parseFloat(a)) / 100 === 0)
  )
    return "ศูนย์บาทถ้วน";
  var e,
    f,
    h,
    i,
    c = ["", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน"],
    d = ["", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า"],
    g = "";
  if (a.indexOf(".") > -1)
    return (
      (h = a.toString().split(".")),
      (a = h[0]),
      (h[1] = parseFloat("0." + h[1])),
      (h[1] = (Math.round(100 * h[1]) / 100).toString()),
      (h = h[1].split(".")),
      h.length > 1 && 1 === h[1].length && (h[1] = h[1].toString() + "0"),
      (a = parseInt(a, 10) + parseInt(h[0], 10)),
      (g = a ? BAHTTEXT(a) : ""),
      parseInt(h[1], 10) > 0 &&
        (g = g.replace("ถ้วน", "") + BAHTTEXT(h[1], "สตางค์")),
      g
    );
  if (a.length > 7) {
    var j = a.substring(0, a.length - 6),
      k = a.slice(-6);
    return (
      BAHTTEXT(j).replace("บาทถ้วน", "ล้าน") + BAHTTEXT(k).replace("ศูนย์", "")
    );
  }
  for (e = a.length, i = 0; i < e; i += 1)
    (f = parseInt(a.charAt(i), 10)) > 0 &&
      (g +=
        e > 2 && i === e - 1 && 1 === f && "สตางค์" !== b
          ? "เอ็ด" + c[e - 1 - i]
          : d[f] + c[e - 1 - i]);
  return (
    (g = g.replace("หนึ่งสิบ", "สิบ")),
    (g = g.replace("สองสิบ", "ยี่สิบ")),
    (g = g.replace("สิบหนึ่ง", "สิบเอ็ด")) + b
  );
}

const Util = {
  getLang: () => {
    if (localStorage.getItem("lang") === null) {
      localStorage.setItem("lang", 0);
      return 0;
    } else return parseInt(localStorage.getItem("lang"));
  },
  getCart: () => {
    var cart = localStorage.getItem("cart");
    if (cart === null) {
      localStorage.setItem("cart", JSON.stringify([]));
      return [];
    } else return JSON.parse(cart);
  },
  getgMap: () => {
    var gMap = localStorage.getItem("gMap");
    if (gMap === null) {
      localStorage.setItem("gMap", JSON.stringify({}));
      return {};
    } else return JSON.parse(gMap);
  },
  setCart: (sheets) => {
    localStorage.setItem("cart", JSON.stringify(sheets));
  },
  addCart: async (id) => {
    var cart = localStorage.getItem("cart");
    if (cart === null) cart = [];
    else cart = JSON.parse(cart);

    if (cart.includes(id)) return false;
    cart.push(id);
    localStorage.setItem("cart", JSON.stringify(cart));
    return true;
  },
  addCartGroup: async (id, gName) => {
    var cart = localStorage.getItem("cart");
    var gMap = localStorage.getItem("gMap");

    if (cart === null) cart = [];
    else cart = JSON.parse(cart);

    if (gMap === null) gMap = {};
    else gMap = JSON.parse(gMap);
    gMap[id] = gName;
    localStorage.setItem("gMap", JSON.stringify(gMap));

    if (cart.includes(id)) return false;
    cart.push(id);
    localStorage.setItem("cart", JSON.stringify(cart));
    return true;
  },
  remCart: async (id) => {
    var cart = localStorage.getItem("cart");
    var gMap = localStorage.getItem("gMap");

    if (cart === null) cart = [];
    else cart = JSON.parse(cart);

    if (gMap === null) gMap = {};
    else gMap = JSON.parse(gMap);

    var filtered = cart.filter((value, index, arr) => {
      return value !== id;
    });
    delete gMap[id];
    localStorage.setItem("gMap", JSON.stringify(gMap));
    localStorage.setItem("cart", JSON.stringify(filtered));
    return true;
  },
  setToken: (token) => {
    localStorage.setItem("auth", token);
  },
  sendPost: async (url, data) => {
    var x = await fetch(process.env.REACT_APP_API_ENDPOINT + url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "jwt " + localStorage.getItem("auth"),
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify(data),
    });
    if (x.status === 401) return { unauthorized: true };
    if (x.status === 500) return { error: true };
    return x.json();
  },
  sendGet: async (url, data) => {
    var x = await fetch(
      process.env.REACT_APP_API_ENDPOINT +
        url +
        "?" +
        new URLSearchParams(data),
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: "jwt " + localStorage.getItem("auth"),
        },
        redirect: "follow",
        referrer: "no-referrer",
      }
    );
    if (x.status === 401) return { unauthorized: true };
    if (x.status === 500) return { error: true };
    return x.json();
  },
  sendGetBlob: async (url, data) => {
    var x = await fetch(
      process.env.REACT_APP_API_ENDPOINT +
        url +
        "?" +
        new URLSearchParams(data),
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: "jwt " + localStorage.getItem("auth"),
        },
        redirect: "follow",
        referrer: "no-referrer",
      }
    );
    return x.blob();
  },
  preHost: (url) => {
    return process.env.REACT_APP_API_ENDPOINT + url;
  },
  checkCover: async (id) => {
    var x = await fetch(process.env.REACT_APP_SHEET_URL + id + "/cover.jpg", {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
    });
    if (!x.ok) return false;
    return (
      process.env.REACT_APP_SHEET_URL +
      id +
      "/cover.jpg" +
      "?" +
      Date.now().toString()
    );
  },
  checkReceipt: async (year, month, store, seller) => {
    try {
      var url = "";
      if (
        parseInt(year) === 2019 ||
        (parseInt(year) === 2020 && parseInt(month) < 5)
      )
        url =
          process.env.REACT_APP_RE_URL +
          year.toString() +
          "/" +
          month.toString() +
          "/" +
          store.toString() +
          "/" +
          seller +
          "/file.jpg";
      else
        url =
          process.env.REACT_APP_RE_URL +
          year.toString() +
          "/" +
          month.toString() +
          "/file.jpg";
      var x = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
      });
      if (!x.ok) return 1;
      return url + "?" + Date.now().toString();
    } catch (err) {
      return false;
    }
  },
  sendFormData: async (url, data) => {
    await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Accept: "*/*",
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: data,
    });
  },
  format2: (num) => {
    var formattedNumber = ("0" + num).slice(-2);
    return formattedNumber;
  },
  alertConfirm: (options) => {
    confirmAlert(options);
  },
  alertError: (message) => {
    const lang = Util.getLang();
    const options = {
      title: lang === 0 ? "เกิดข้อผิดพลาด" : "Error",
      message: message,
      buttons: [
        {
          label: lang === 0 ? "รับทราบ" : "Understood",
          onClick: async () => {},
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    };
    Util.alertConfirm(options);
  },
  alertSuccess: (message, func) => {
    const lang = Util.getLang();
    const options = {
      title: lang === 0 ? "สำเร็จ" : "Success",
      message: message,
      buttons: [
        {
          label: lang === 0 ? "รับทราบ" : "Understood",
          onClick: async () => {
            await func();
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    };
    Util.alertConfirm(options);
  },
  sleep: (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  bahttext: (a) => {
    return BAHTTEXT(a);
  },
};

export default Util;
