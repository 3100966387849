import React from "react";
import Fade from "react-reveal/Fade";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import style from "./Style.module.css";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
import { Container, Visible, Hidden } from "react-grid-system";

const faqSell = [
  {
    q: "📌ลงขายกับ moresheet ดีอย่างไร?",
    a: "เรามีผู้ติดตามและฐานลูกค้าจำนวนมากให้อยู่แล้ว พร้อมโปรโมทให้ตลอด เป็นตัวกลางที่พร้อมบริการ สะดวกสบายกว่าเปิดขายเอง ผู้ขายไม่ต้องยุ่งนั่งตอบลูกค้าเอง ไม่ต้องส่งชีทเอง เอาเวลาไปอ่านสอบของตัวเองได้เต็มที่ รอรับเงินทุกสิ้นเดือนอย่างเดียว!\n\nอีกทั้งเรามีกฎกติกาในการขาย รายงานสถานะยอดขายที่ชัดเจน สามารถตรวจสอบได้ทุกเมื่อ",
  },
  {
    q: "📌อยากลงขายชีทต้องทำอย่างไร?",
    a: "เข้าเว็บ https://moresheet.co/ > กดเข้าสู่ระบบผ่าน facebook > ไปที่หน้า “ขายชีท” > กรอกข้อมูลเพื่อลงทะเบียน > อัพโหลดชีทเองได้เลย ! จากนั้นรอให้ทีมงานตรวจสอบมาตรฐานของชีทและกดอนุมัติ หากชีทที่ส่งมายังไม่ได้มาตรฐานหรือควรปรับแก้ไข ทางเราจะแจ้งกลับไป เพื่อให้ชีทในระบบมีคุณภาพ เพื่อประโยชน์ของทั้งผู้ซื้อและผู้ขายให้มากที่สุด",
  },
  {
    q: "📌การตั้งราคาชีทควรเป็นอย่างไร?",
    a: "ต้องบอกก่อนว่าจริงๆมาตรฐานที่เคยทำและตั้งไว้คือ หน้าละ 1 บาท แต่กลับมีบางส่วนได้พยายามทำจำนวนหน้ามาเยอะๆ เพิ่มเติมสิ่งที่ไม่จำเป็นเข้าไปในชีทเพื่อต้องการเพิ่มรายได้ในส่วนนี้ และผู้ที่ตั้งใจทำชีทมากๆ สรุปกระชับ แต่จำนวนน้อย กลับได้รายได้น้อยกว่าที่ควร ทีมงานจึงปรับให้ผู้เขียนตั้งราคาชีทตามคุณค่าที่คู่ควร\n\nเช่น ชีทที่มีจำนวนหน้าเหมาะสมกับเนื้อหา อ่านได้เข้าใจ หรือมีเนื้อหาแน่นแบบเน้นๆ ถอดความจากเสียงอาจารย์ หาข้อมูลมาประกอบ ต้องตั้งใจฟังและเข้าเรียนมากเป็นพิเศษ ควรที่จะเพิ่มราคาให้เหมาะสม หรือเท่าที่ผู้ซื้อจะสามารถยอมจ่ายได้\n\nหากทีมงานเห็นว่า ราคาที่ตั้งมาน้อยกว่าที่ควร เราจะยังไม่อนุมัติและเสนอให้ผู้ขายตั้งราคาใหม่",
  },
  {
    q: "📌สัดส่วนรายได้เป็นอย่างไร?",
    a: "เนื่องจากทาง moresheet ต้องบริการลูกค้าทั้งผู้ซื้อและผู้ขายให้ดีที่สุด ทางเราจึงต้องมีค่าใช้จ่ายในการพัฒนาระบบอยู่เสมอ รวมถึงการโปรโมทชีทซึ่งช่วยให้ทางผู้ขายมีรายได้และประหยัดเวลา ดังนั้นทางผู้ขายจะมีรายได้ 60% ของยอดขายทั้งหมด “เป็นประจำอย่างต่อเนื่องทุกๆเดือน” และชีทจะอยู่ในระบบตลอดจนกว่าทางผู้ขายจะแจ้งลบ",
  },
  {
    q: "📌การตัดรอบรายได้เป็นอย่างไร?",
    a: "ทางเราจะรวบรวมจำนวนยอดขายโดยตัดยอดทุกๆวันสุดท้ายของแต่ละเดือน และโอนเงินให้ผู้ขายภายใน 7 วันหลังตัดยอด ตามเลขบัญชีที่แจ้งมาในเว็บไซต์ ผู้ขายสามารถเช็คสถานะยอดขายและรับข้อมูลการโอนเงินได้บนเว็บไซต์เลย",
  },
  {
    q: "📌ชีทสรุปที่เราจะอนุมัติให้ผ่านควรเป็นอย่างไร?",
    a: "ชีทสรุปที่คนต้องการคือ เป็นเนื้อหาแบบ “สรุป” สั้น กระชับ เข้าใจง่าย เน้นใจความสำคัญ เน้นแต่เนื้อๆ ไม่ใช่ copy ผลงานคนอื่นมาเขียน ทำให้เข้าใจรายละเอียดโดยรวม ผู้อ่านต้องการประหยัดเวลาในการอ่าน\n\n- หน้าแรกของทุกชีทสรุปต้องมีเขียน “outline” ที่บอกหัวข้อเนื้อหาภายในเล่ม ผู้ซื้อจะได้ทราบคร่าวๆว่าภายในมีเนื้อหาอะไรบ้าง\n\n- เน้นย้ำเรื่องความถูกต้องของชีท โปรดตรวจสอบให้ละเอียดทั้งตัวสะกดและเนื้อหา\n\n- หากส่งเป็นลายมือ ขอให้เขียนลายมือดีๆ ตัวไม่เล็กและเบียดจนเกินไป ให้อ่านง่ายๆ***(หากลายมืออ่านยาก จะไม่ทำการอนุมัติให้ เนื่องจากมีผลต่อความรู้สึกลูกค้า)",
  },
];

class Sell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {
        user: {
          infoEnter: true,
        },
      },
      FAQ: [false, false, false, false, false, false],
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className={style.shopPage}>
          <Nav current="about" user={this.state.data.user} />
          <Container>
            <Hidden xs sm>
              <div className={style.headerFAQ}>คำถามที่พบบ่อย</div>
              <div className={style.FAQblank} />
              <div className={style.typeHeader}>สำหรับผู้ขายชีท</div>
              {this.renderFAQ(false)}
              <div className={style.bottomBlank} />
            </Hidden>

            <Visible xs sm>
              <div className={style.headerFAQ + " " + style.mobile}>
                คำถามที่พบบ่อย
              </div>
              <div className={style.FAQblank + " " + style.mobile} />
              <div className={style.typeHeader + " " + style.mobile}>
                สำหรับผู้ขายชีท
              </div>
              {this.renderFAQ(true)}
              <div className={style.bottomBlank} />
            </Visible>
          </Container>
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  renderFAQ = (mobile) => {
    var out = [];
    for (var i = 0; i < faqSell.length; i++) {
      var qa = faqSell[i];
      if (!mobile)
        out.push(
          <React.Fragment key={"FAQ" + i.toString()}>
            <div className={style.faqBull} id={i} onClick={this.toggleFAQ}>
              {qa.q}
            </div>
            {this.state.FAQ[i] && (
              <Fade top duration={100}>
                <div className={style.FAQans}>{this.nl2br(qa.a)}</div>
              </Fade>
            )}
            <div className={style.fLine} />
          </React.Fragment>
        );
      else
        out.push(
          <React.Fragment key={"FAQ" + i.toString()}>
            <div
              className={style.faqBull + " " + style.mobile}
              id={i}
              onClick={this.toggleFAQ}
            >
              {qa.q}
            </div>
            {this.state.FAQ[i] && (
              <Fade top duration={100}>
                <div className={style.FAQans + " " + style.mobile}>
                  {this.nl2br(qa.a)}
                </div>
              </Fade>
            )}
            <div className={style.fLine} />
          </React.Fragment>
        );
    }
    return out;
  };

  toggleFAQ = (event) => {
    var num = parseInt(event.currentTarget.id);
    var x = this.state.FAQ;
    x[num] = !x[num];
    this.setState({ FAQ: x });
  };

  nl2br = (text) => {
    return text.split("\n").map((item, key) => {
      return (
        <React.Fragment key={key}>
          {item}
          <br />
        </React.Fragment>
      );
    });
  };
}

export default withRouter(withAlert()(Sell));
