import React from "react";
import { Link } from "react-router-dom";
import Util from "../../Util";
import style from "./Style.module.css";
import { Container } from "react-grid-system";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";

class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        user: {
          name: "",
          perm: [],
        },
      },
      id: "",
    };
  }

  async componentDidMount() {
    var data = await Util.sendPost("/api/moresheetStaff/sheetMeta", {});
    if (!data.success) this.props.history.push("/staff");
    else {
      this.setState({ data: data.data });
    }
  }

  render() {
    return (
      <div className={style.sheetPage}>
        <Container>
          <div className={style.header}>แก้ข้อมูลชีท + tools ต่างๆ</div>
          <div className={style.subheader}>
            Staff : {this.state.data.user.name}
          </div>
          <hr />
          <input
            type="text"
            value={this.state.id}
            className={style.searchBar}
            placeholder="id ของชีท หาได้จากท้าย url ของชีท เช่น 5e169c2ac86ff35a396fc6c7"
            onChange={(event) => {
              this.setState({ id: event.currentTarget.value });
            }}
          />
          <Link to={"/staff/sheetedit?" + this.state.id}>
            <div className={style.goButt}>แก้ไข</div>
          </Link>
          <hr />
          <a href="/temp/auto.pdf">
            <div className={style.goButt}>ไฟล์ pdf หน้าปกเพื่อนำไป merge</div>
          </a>
        </Container>
      </div>
    );
  }

  componentWillUnmount() {}
}

export default withRouter(withAlert()(EditSheet));
