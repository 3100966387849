import React from "react";
import style from "./Style.module.css";
import logo from "../../asset/fb.png";

class FacebookLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        onClick={() => {
          this.props.loginFacebook();
        }}
        className={style.goldButton}
      >
        <img alt="" src={logo} className={style.fLogo} />
      </div>
    );
  }
}

export default FacebookLogin;
