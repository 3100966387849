import React from "react";
import { Redirect } from "react-router-dom";
import SelectSearch from "react-select-search";
import Util from "../../Util";
import style from "./Style.module.css";
import { Container } from "react-grid-system";

class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      data: {
        user: {},
        stores: [],
        showAll: false,
      },
      store: -2,
      loading: false,
    };
  }

  async componentDidMount() {
    var data = await Util.sendPost("/api/moresheetStaff/getStatMeta", {});
    if (!data.success) await this.setState({ redirect: true });
    if (data.data.showAll)
      data.data.stores.unshift({ code: -1, name: ["ทุกมหาวิทยาลัย", "All"] });
    await this.setState({ data: data.data });
    await this.setState({ store: data.data.stores[0].code });
  }

  render() {
    return (
      <div className={style.sheetPage}>
        {this.state.redirect && <Redirect to="/staff/" />}
        <Container>
          <div className={style.header}>ข้อมูลทางสถิติ</div>
          <div className={style.explain}>เลือกมหาวิทยาลัยเพื่อดูข้อมูล</div>
          <hr />
          <SelectSearch
            className="select-search-box-verifySheet"
            options={this.genUniOptions()}
            value={this.state.store.toString()}
            onChange={(value) => this.setUni(value)}
          />
          <hr />
          {this.state.loading && (
            <div className={style.loading}>กำลัง download...</div>
          )}
          <div className={style.dataPull} onClick={this.pullRev}>
            ข้อมูลรายได้ จำนวนชีทที่ขายได้ รายเดือน คลิก
          </div>
          <div className={style.dataPull} onClick={this.pullUser}>
            ข้อมูลจำนวนผู้ใช้สมัครใหม่ รายเดือน คลิก
          </div>
          <div className={style.dataPull} onClick={this.pullSeller}>
            ข้อมูลจำนวนผู้ขายสมัครใหม่ รายเดือน คลิก
          </div>
        </Container>
      </div>
    );
  }

  componentWillUnmount() {}

  genUniOptions = () => {
    if (this.state.data.stores.length === 0) return [{ value: "0", name: "" }];
    return this.state.data.stores.map(({ name, code }) => ({
      value: code.toString(),
      name: name.join(" - "),
    }));
  };

  setUni = async (value) => {
    this.setState({ store: parseInt(value.value) });
  };

  pullRev = async (event) => {
    this.setState({ loading: true });
    var data = await Util.sendPost("/api/moresheetStaff/getStat", {
      query: "revenue",
      store: this.state.store,
    });
    if (!data.success) {
      alert(data.msg[0]);
      return;
    }
    this.exportCSVFile(
      ["revenue", "# of sheets sold", "university", "month", "year"],
      data.data,
      "revenue"
    );
    this.setState({ loading: false });
  };
  pullUser = async (event) => {
    this.setState({ loading: true });
    var data = await Util.sendPost("/api/moresheetStaff/getStat", {
      query: "accounts",
      store: this.state.store,
    });
    if (!data.success) {
      alert(data.msg[0]);
      return;
    }
    this.exportCSVFile(
      ["# of new account", "month", "year", "university"],
      data.data,
      "accounts"
    );
    this.setState({ loading: false });
  };

  pullSeller = async (event) => {
    this.setState({ loading: true });
    var data = await Util.sendPost("/api/moresheetStaff/getStat", {
      query: "sellers",
      store: this.state.store,
    });
    if (!data.success) {
      alert(data.msg[0]);
      return;
    }
    this.exportCSVFile(
      ["# of new seller", "month", "year", "university"],
      data.data,
      "sellers"
    );
    this.setState({ loading: false });
  };

  convertToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "\ufeff";
    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index].toString();
      }
      str += line + "\r\n";
    }
    return str;
  };

  exportCSVFile = (headers, items, fileTitle) => {
    if (headers) items.unshift(headers);
    var jsonObject = JSON.stringify(items);
    var csv = this.convertToCSV(jsonObject);
    var exportedFilenmae = fileTitle + ".csv";
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
}

export default EditSheet;
