import React from "react";
import Util from "../../Util";
import Nav from "../../Components/Nav";
import style from "./Style.module.css";
import { Container } from "react-grid-system";
import Footer from "../../Components/Footer";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
import UnRegForm from "../../Components/EditForm";

class Sell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: {
        user: {
          infoEnter: true,
        },
      },
    };
  }

  async componentDidMount() {
    var data = await Util.sendPost("/api/seller/getSellEdit", {});
    if (data.success) {
      var inf = data.data.info.bankInfo.split(",");
      data.data.info.bankName = inf[0];
      data.data.info.bankNumber = inf[1];
      await this.setState({ loaded: true, data: data.data });
      localStorage.removeItem("ack");
    } else this.props.history.push("/seller");
  }

  render() {
    return (
      <React.Fragment>
        <div className={style.shopPage}>
          <Nav current="seller" user={this.state.data.user} />
          <Container>
            <div className={style.blanker} />
            {this.state.loaded && (
              <UnRegForm save={this.save} info={this.state.data.info} />
            )}
          </Container>
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  save = async (data) => {
    data.bankInfo = data.bankName.trim() + "," + data.bankNumber.trim();
    var data2 = await Util.sendPost("/api/seller/edit", data);
    if (data2.success) {
      this.props.alert.show("สำเร็จ", { type: "success", timeout: 1000 });
      setTimeout(() => {
        this.props.history.push("/seller/mysheet");
      }, 1000);
    } else
      this.props.alert.show(data2.msg[0], { type: "error", timeout: 1000 });
  };
}

export default withRouter(withAlert()(Sell));
