import React from "react";
import { Redirect } from "react-router-dom";
import SelectSearch from "react-select-search";
import Util from "../../Util";
import style from "./Style.module.css";
import "./style.css";

class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false,
      data: {
        user: {},
        stores: [],
      },
      store: -1,
      enddate: new Date().toISOString(),
      startdate: new Date(Date.now() - 3600 * 1000 * 24).toISOString(),
    };
  }

  async componentDidMount() {
    var data = await Util.sendPost("/api/moresheetStaff/getVerifySheet", {});
    if (!data.success) await this.setState({ redirect: true });
    await this.setState({ data: data.data });
    await this.setState({ store: data.data.stores[0].code });
  }

  render() {
    return (
      <div className={style.sheetPage}>
        {this.state.redirect && <Redirect to="/staff/" />}
        <div className={style.container}>
          <div className={style.header}>
            Download หน้าปกชีทที่ผ่านการ approve
          </div>
          <div className={style.explain}>เลือกมหาวิทยาลัย</div>
          <hr />

          <SelectSearch
            className="select-search-box-verifySheet"
            options={this.genUniOptions()}
            value={this.state.store.toString()}
            onChange={(value) => this.setUni(value)}
          />
          <div className={style.explain}>
            จากวันที่ / เวลา ตาม{" "}
            <a href="https://blog.nextzy.me/iso8601-7ca86f8acab7">ISO FORMAT</a>
          </div>
          <input
            className={style.textField}
            type="text"
            value={this.state.startdate}
            onChange={(event) => {
              this.setState({ startdate: event.currentTarget.value });
            }}
          />
          <div className={style.explain}>ถึงวันที่ / เวลา</div>
          <input
            className={style.textField}
            type="text"
            value={this.state.enddate}
            onChange={(event) => {
              this.setState({ enddate: event.currentTarget.value });
            }}
          />
          <a
            href={`/api/public/approveFromStore?store=${this.state.store}&from=${this.state.startdate}&to=${this.state.enddate}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={style.requestButton}>
              Download ชีทที่ถูก approve ในช่วงเวลา
            </div>
          </a>
        </div>
      </div>
    );
  }

  componentWillUnmount() {}

  genUniOptions = () => {
    if (this.state.data.stores.length === 0) return [{ value: "0", name: "" }];
    return this.state.data.stores.map(({ name, code, count }) => ({
      value: code.toString(),
      name: name.join(" - "),
      highlight: false,
    }));
  };

  setUni = async (value) => {
    this.setState({ store: parseInt(value.value) });
  };
}

export default EditSheet;
