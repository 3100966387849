import React from "react";
import Util from "../../Util";
import style from "./Style.module.css";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
import { Container } from "react-grid-system";

class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        code: -1,
        name: ["", ""],
        color: ["#000000", "#000000", "#000000", "#000000"],
        driveIn: [],
        subject: "",
        description: "",
      },
      cover: false,
      updCover: false,
      updTemplate: false,
      template: false,
      wf: false,
      coverExample: false,
    };
  }

  async componentDidMount() {
    var unicode = window.location.search.split("?code=")[1];
    var data = await Util.sendGet("/api/moresheetStaff/getUni", {
      code: unicode,
    });
    if (!data.success) {
      await alert(data.msg[0]);
      return this.props.history.push("/staff");
    }
    await this.setState({ data: data.data });
    await this.setState({
      cover: process.env.REACT_APP_LOGO_URL + unicode + ".jpg?" + Date.now(),
    });
    await this.setState({
      template:
        process.env.REACT_APP_TEMPLATE_URL + unicode + ".jpg?" + Date.now(),
    });
    await this.coverExam();
  }

  render() {
    return (
      <React.Fragment>
        <div className={style.sheetPage}>
          <Container>
            <div className={style.container}>
              <div className={style.blankTop} />
              <div className={style.header}>Staff Store Management</div>
              <hr />
              <div className={style.label}>รหัส: {this.state.data.code}</div>
              <div className={style.label}>ชื่อไทย</div>
              <input
                type="text"
                className={style.textField}
                value={this.state.data.name[0]}
                onChange={(event) => {
                  this.changeNameData(0, event.currentTarget.value);
                }}
              />
              <div className={style.label}>ชื่ออังกฤษ/ตัวย่อ</div>
              <input
                type="text"
                className={style.textField}
                value={this.state.data.name[1]}
                onChange={(event) => {
                  this.changeNameData(1, event.currentTarget.value);
                }}
              />
              <div className={style.label}>Subject</div>
              <input
                type="text"
                className={style.textField}
                value={this.state.data.subject}
                onChange={(event) => {
                  this.changeSubjectData(event.currentTarget.value);
                }}
              />
              <div className={style.label}>Description</div>
              <input
                type="text"
                className={style.textField}
                value={this.state.data.description}
                onChange={(event) => {
                  this.changeDescriptionData(event.currentTarget.value);
                }}
              />
              <div className={style.label}>รหัสสีเงา Header</div>
              <input
                type="text"
                className={style.textField}
                value={this.state.data.color[0]}
                onChange={(event) => {
                  this.changeColorData(0, event.currentTarget.value);
                }}
              />
              <div className={style.label}>รหัสสีตัวหนังสือ</div>
              <input
                type="text"
                className={style.textField}
                value={this.state.data.color[1]}
                onChange={(event) => {
                  this.changeColorData(1, event.currentTarget.value);
                }}
              />
              <div className={style.label}>รหัสสีนามปากกา</div>
              <input
                type="text"
                className={style.textField}
                value={this.state.data.color[2]}
                onChange={(event) => {
                  this.changeColorData(2, event.currentTarget.value);
                }}
              />
              <div className={style.label}>รหัสสีราคา</div>
              <input
                type="text"
                className={style.textField}
                value={this.state.data.color[3]}
                onChange={(event) => {
                  this.changeColorData(3, event.currentTarget.value);
                }}
              />
              <div className={style.label}>ให้ Drive ไปโผล่ใน ม ไหนบ้าง</div>
              {this.state.wf && (
                <div className={style.labelRed}>WRONG JSON FORMAT!!</div>
              )}
              <textarea
                type="text"
                className={style.textArea}
                value={
                  Array.isArray(this.state.data.driveIn)
                    ? JSON.stringify(this.state.data.driveIn)
                    : this.state.data.driveIn
                }
                onChange={(event) => {
                  this.changeDriveInData(event.currentTarget.value);
                }}
              />

              <div className={style.label}>Logo</div>
              {this.state.updCover ? (
                <div className={style.uploadInfo}>กำลังอัพโหลด</div>
              ) : (
                <React.Fragment>
                  <input
                    type="file"
                    className={style["fileField"]}
                    onChange={(event) => {
                      this.uploadCover(event.currentTarget.files[0]);
                    }}
                  />
                  {this.state.cover ? (
                    <a
                      href={this.state.cover}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt=""
                        src={this.state.cover}
                        className={style["coverImage"]}
                      />
                    </a>
                  ) : (
                    <div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
                  )}
                </React.Fragment>
              )}
              <div className={style.label}>Template</div>
              {this.state.updTemplate ? (
                <div className={style.uploadInfo}>กำลังอัพโหลด</div>
              ) : (
                <React.Fragment>
                  <input
                    type="file"
                    className={style["fileField"]}
                    onChange={(event) => {
                      this.uploadTemplate(event.currentTarget.files[0]);
                    }}
                  />
                  {this.state.cover ? (
                    <a
                      href={this.state.template}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt=""
                        src={this.state.template}
                        className={style["coverImage2"]}
                      />
                    </a>
                  ) : (
                    <div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
                  )}
                </React.Fragment>
              )}
              <button
                className={style.saveButton}
                onClick={() => this.saveUni()}
              >
                Save
              </button>

              <div className={style.label}>Cover Example</div>
              {this.state.coverExample ? (
                <a
                  href={this.state.coverExample}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt=""
                    src={this.state.coverExample}
                    className={style["coverImage2"]}
                  />
                </a>
              ) : (
                <div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
              )}
              <div style={{ marginBottom: 50 }} />
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  changeNameData = (index, data) => {
    var x = this.state.data;
    x.name[index] = data;
    this.setState({ data: x });
  };

  changeSubjectData = (data) => {
    var x = this.state.data;
    x.subject = data;
    this.setState({ data: x });
  };

  changeDescriptionData = (data) => {
    var x = this.state.data;
    x.description = data;
    this.setState({ data: x });
  };

  changeColorData = (index, data) => {
    var x = this.state.data;
    x.color[index] = data;
    this.setState({ data: x });
  };

  changeDriveInData = (data) => {
    var x = this.state.data;
    try {
      x.driveIn = JSON.parse(data);
      this.setState({ wf: false });
    } catch (err) {
      x.driveIn = data;
      this.setState({ wf: true });
    }
    this.setState({ data: x });
  };

  uploadCover = async (file) => {
    await this.setState({ updCover: true });
    var data = await Util.sendPost("/api/moresheetStaff/uploadUniLogoUrl", {
      id: this.state.data.code,
    });

    var formData = new FormData();
    formData.append("acl", "public-read");
    var keys = Object.keys(data.data.fields);
    for (var i = 0; i < keys.length; i++)
      formData.append(keys[i], data.data.fields[keys[i]]);
    formData.append("file", file);
    await Util.sendFormData(data.data.url, formData);

    await this.setState({ updCover: false });
    await this.setState({
      cover:
        process.env.REACT_APP_LOGO_URL +
        this.state.data.code +
        ".jpg?" +
        Date.now(),
    });
  };

  uploadTemplate = async (file) => {
    await this.setState({ updTemplate: true });
    var data = await Util.sendPost("/api/moresheetStaff/uploadUniTemplateUrl", {
      id: this.state.data.code,
    });

    var formData = new FormData();
    formData.append("acl", "public-read");
    var keys = Object.keys(data.data.fields);
    for (var i = 0; i < keys.length; i++)
      formData.append(keys[i], data.data.fields[keys[i]]);
    formData.append("file", file);
    await Util.sendFormData(data.data.url, formData);

    await this.setState({ updTemplate: false });
    await this.setState({
      template:
        process.env.REACT_APP_TEMPLATE_URL +
        this.state.data.code +
        ".jpg?" +
        Date.now(),
    });
    await Util.sendPost("/api/moresheetStaff/pullTemplate", {
      id: this.state.data.code,
    });
    await this.coverExam();
  };

  saveUni = async () => {
    var data = await Util.sendPost("/api/moresheetStaff/saveDrive", {
      code: this.state.data.code,
      name: this.state.data.name,
      driveIn: this.state.data.driveIn,
      color: this.state.data.color,
      subject: this.state.data.subject,
      description: this.state.data.description,
    });
    if (data.success) {
      alert("save แล้ว");
      await this.coverExam();
    } else alert("something broke ask poom");
  };

  coverExam = async () => {
    var b = await Util.sendGetBlob("/api/moresheetStaff/uniCoverExample", {
      code: this.state.data.code,
    });
    this.setState({ coverExample: URL.createObjectURL(b) });
  };
}

export default withRouter(withAlert()(EditSheet));
