import React from "react";
import Util from "../../Util";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import style from "./Style.module.css";
import { Link } from "react-router-dom";
import { Container } from "react-grid-system";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
import cart from "../../asset/nocart.svg";

class Sell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {
        user: {
          infoEnter: true,
        },
        cart: [],
      },
      nameMap: {},
      promo: "",
      realPromo: "",
      desPromo: "",
      pricePromo: -1,
    };
  }

  async componentDidMount() {
    await this.verifyCart(true);
  }

  render() {
    return (
      <React.Fragment>
        <div className={style.shopPage}>
          <Nav current="" user={this.state.data.user} />
          <div className={style.topBlank} />
          <Container>
            {this.renderCart()}
            {this.state.data.cart.length !== 0 && this.state.data.user && (
              <React.Fragment>
                <div className={style.promoContainer}>
                  <div className={style.promoHead}>รหัสส่วนลด: </div>
                  <input
                    className={style.promoFill}
                    type="text"
                    value={this.state.promo}
                    onChange={(event) =>
                      this.setState({ promo: event.currentTarget.value.trim() })
                    }
                  />
                  <div className={style.checkPromo} onClick={this.checkPromo}>
                    ใช้
                  </div>
                </div>
                {this.state.realPromo !== "" && (
                  <div className={style.desPromo}>
                    รหัสส่วนลด {this.state.realPromo} : {this.state.desPromo}
                  </div>
                )}
              </React.Fragment>
            )}
            {this.state.data.cart.length !== 0 && (
              <div
                className={
                  this.state.realPromo === ""
                    ? style.totalPrice
                    : style.greenTotalPrice
                }
              >
                {"รวม : " + this.getSum() + " บาท"}
              </div>
            )}

            {this.renderGo()}
            <div className={style.redwarn}>
              หากการสร้างรายการสั่งซื้อใช้เวลานาน / ไม่สำเร็จ,
              หมายความว่าระบบธนาคารมีปัญหา โปรดลองอีกครั้งภายใน 5-10
              นาทีข้างหน้า
            </div>
          </Container>
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  checkPromo = async (event) => {
    var cart = Util.getCart();
    var data = await Util.sendPost("/api/user/getPromo", {
      sheets: cart,
      promoCode: this.state.promo,
    });
    if (data.success) {
      this.props.alert.show("ตรวจสอบรหัสส่วนลดสำเร็จ", { type: "success" });
      this.setState({
        desPromo: data.data.description,
        realPromo: data.data.code,
        pricePromo: data.data.price,
      });
    } else {
      this.props.alert.show(data.msg[0], { type: "error" });
      this.setState({ desPromo: "", realPromo: "", pricePromo: -1 });
    }
  };

  getSum = () => {
    if (this.state.pricePromo >= 0) return this.state.pricePromo.toString();
    var sum = 0;
    for (var i = 0; i < this.state.data.cart.length; i++)
      sum += this.state.data.cart[i].price;
    return sum.toString();
  };

  renderCart = () => {
    if (this.state.data.cart.length === 0)
      return (
        <React.Fragment>
          <div className={style.noContainer}>
            <div className={style.noCartLogo}>
              <img alt=" " src={cart} className={style.noCartLogoSVG} />
            </div>
            <div className={style.noCart}>ไม่มีชีทในตะกร้า</div>
            <Link to={"/shop"}>
              <div className={style.noCartButt}>ไปสู่หน้าเลือกซื้อชีท</div>
            </Link>
          </div>
        </React.Fragment>
      );
    return (
      <React.Fragment>
        <div className={style.bigheader}>
          {"คุณมี " + this.state.data.cart.length + " ชีทในตะกร้า"}
        </div>
        {this.state.data.cart.map((obj) => (
          <React.Fragment key={obj._id}>
            {this.state.nameMap[obj._id] && (
              <div className={style.headerGroup}>
                ชีทจากแพคเกจ {this.state.nameMap[obj._id]}
              </div>
            )}
            {this.state.nameMap[obj._id] === "" && (
              <div className={style.headerGroup}>ชีททั่วไป</div>
            )}
            <div className={style.sheetContain}>
              <div className={style.sheetLeft}>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={"/infoSheet?" + obj._id}
                >
                  <img
                    className={style.sheetCover}
                    src={
                      process.env.REACT_APP_SHEET_URL + obj._id + "/cover.jpg"
                    }
                    alt="cover"
                  ></img>
                </Link>
                <div className={style.sheetLeftInfo}>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={"/infoSheet?" + obj._id}
                  >
                    <div className={style.sheetName}>{obj.name}</div>
                  </Link>
                  <div className={style.sheetAuth}>
                    {"By " + obj.authorCode}
                  </div>
                </div>
              </div>
              <div className={style.sheetRight}>
                <div
                  className={style.sheetDelete}
                  onClick={() => this.removeCart(obj._id)}
                >
                  ลบ
                </div>
                <div className={style.sheetPrice}>{obj.price + " บาท"}</div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  createOrder = async () => {
    var cart = Util.getCart();
    const lang = Util.getLang();
    this.props.alert.show("รอสักครู่...", { type: "info" });
    var data = await Util.sendPost("/api/user/createOrder", {
      sheets: cart,
      promoCode: this.state.realPromo,
    });
    if (data.success) {
      Util.setCart([]);
      this.props.history.push("/user/myorder?" + data.data.id);
    } else this.props.alert.show(data.msg[lang], { type: "error" });
  };

  renderGo = () => {
    if (this.state.data.cart.length === 0)
      return <React.Fragment></React.Fragment>;
    if (this.state.data.user)
      return (
        <React.Fragment>
          <div className={style.goButt} onClick={() => this.createOrder()}>
            สร้างรายการสั่งซื้อ
          </div>
        </React.Fragment>
      );
    else
      return (
        <Link to="/login?cart">
          <div className={style.loginButt}>เข้าสู่ระบบเพื่อทำการสั่งซื้อ</div>
        </Link>
      );
  };

  removeCart = async (id) => {
    await Util.remCart(id);
    await this.verifyCart(false);
    this.props.alert.show("ลบชีทออกจากตะกร้าแล้ว", { type: "success" });
  };

  verifyCart = async (alr) => {
    var cart = Util.getCart();
    var gMap = Util.getgMap();
    var data = await Util.sendPost("/api/public/getCart", { cart });
    if (data.success) {
      if (cart.length !== data.data.updCart.length && alr)
        this.props.alert.show(
          "ชีทบางอันถูกนำออกจากตะกร้า เนื่องจากคุณเป็นเจ้าของอยู่แล้ว",
          { type: "info" }
        );
      cart = data.data.cart.sort((a, b) => {
        if (!gMap[a._id]) return 1;
        if (!gMap[b._id]) return -1;
        if (gMap[a._id] === gMap[b._id]) return 0;
        return gMap[a._id] < gMap[b._id] ? -1 : 1;
      });
      var nameMap = {};
      var pre = -1;
      for (var i = 0; i < cart.length; i++) {
        var gnow = gMap[cart[i]._id] ? gMap[cart[i]._id] : "";
        if (pre !== gnow) nameMap[cart[i]._id] = gnow;
        pre = gnow;
      }
      Util.setCart(cart.map((c) => c._id));
      await this.setState({ nameMap, data: data.data });
    }
  };
}

export default withRouter(withAlert()(Sell));
