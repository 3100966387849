import React from 'react';
import Util from '../../Util';
import Nav from '../../Components/Nav';
import Footer from '../../Components/Footer';
import SellSheet from '../../Components/ShopSheet';
import style from './Style.module.css';
import {Visible,Hidden,Container} from 'react-grid-system';
import InfiniteScroll from 'react-infinite-scroller';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import { createRef } from 'react';

class Sell extends React.Component {

	ref = createRef();

	constructor(props) {
		super(props);
		this.state = {
			loading:false,
			redirectFail:false,
			redirectSheet:false,
			sheetID:'',
			data:{
				user:{
					infoEnter:true,
				},
				store:0,
				storeList:[],
			},
			sheets: {
				page:0,
				loading:false,
				hasmore:true,
				disp:[],
				timeStamp:0,
			},
			filter:{
				sort:'date',
				search:'',
				sem:'any',
				year:'0',
				store:-1,
				cat:-1
			},
			storeData:{},
			pagemutex:1000000,
			allset:false
		};
	}
	
	async componentDidMount() {
		window.history.pushState({}, '', '');
		window.onpopstate = (event) => { };
		window.scrollTo(0, 0);
		var data = await Util.sendPost('/api/public/getShopDrive',{});
		if(data.success)
		{
			await this.setState({data:data.data});
			var fil=this.state.filter;
			fil.store=data.data.store;
			if(this.props.location.search.trim().length!==0)
				fil.store = parseInt(this.props.location.search.substring(1));
			await this.setState({filter:fil});
			this.reloadSheets();
			await this.reloadStoreData(data.data)
			await this.setState({allset:true});
		}
		else
			await this.setState({redirectFail:true});
		window.onpopstate = (event) => { window.close() };
	}

	render() {
		return (
			<React.Fragment>
				<div className={style.shopPage}>
					<Nav current='shop' user={this.state.data.user}/>
					<Container>
						<div className={style.blanker} /> 
						<img src={process.env.REACT_APP_LOGO_URL+this.state.filter.store.toString()+'.jpg'} className={style.uniPic} alt=''/>
						{this.state.storeData.description && <div className={style.description}>{this.state.storeData.description}</div>}
						<Hidden xs sm md>
							<div className={style.filterBar}>
								<input type='text' placeholder='ค้นหาชีท...' className={style.search} value={this.state.filter.search} onChange={(event)=>this.setFilter('search',event.currentTarget.value)}/>
								<select className={style.semSelector} value={this.state.filter.sem} onChange={(event)=>this.setFilter('sem',event.currentTarget.value)}>{this.genTermOptions()}</select>
								<select className={style.semSelector} value={this.state.filter.year} onChange={(event)=>this.setFilter('year',event.currentTarget.value)}>{this.genYearOptions()}</select>
								<select className={style.semSelector} value={this.state.filter.cat} onChange={(event)=>this.setFilter('cat',event.currentTarget.value)}>{this.genCatOptions()}</select>
								<select className={style.semSelector} value={this.state.filter.sort} onChange={(event)=>this.setFilter('sort',event.currentTarget.value)}>{this.genSortOptions()}</select>
							</div>
						</Hidden>
						<Visible xs sm md>
							<div className={style.barMobile+' '+style.searcher}>
								<input type='text' placeholder='ค้นหาชีท...' className={style.search+' '+style.mobile} value={this.state.filter.search} onChange={(event)=>this.setFilter('search',event.currentTarget.value)}/>
							</div>
							<div className={style.barMobile+' '+style.filter}>
								<select className={style.semSelector+' '+style.mobile} value={this.state.filter.sem} onChange={(event)=>this.setFilter('sem',event.currentTarget.value)}>{this.genTermOptions()}</select>
								<select className={style.semSelector+' '+style.mobile+' '+style.last} value={this.state.filter.year} onChange={(event)=>this.setFilter('year',event.currentTarget.value)}>{this.genYearOptions()}</select>
							</div>
							<div className={style.barMobile+' '+style.filter}>
								<select className={style.semSelector+' '+style.mobile} value={this.state.filter.cat} onChange={(event)=>this.setFilter('cat',event.currentTarget.value)}>{this.genCatOptions()}</select>
								<select className={style.semSelector+' '+style.mobile+' '+style.last} value={this.state.filter.sort} onChange={(event)=>this.setFilter('sort',event.currentTarget.value)}>{this.genSortOptions()}</select>
							</div>
						</Visible>
						<div className={style.spacebot} />
						<div className={style.fillbot}>
							<InfiniteScroll
								ref={this.ref}
								key="scroller"
								pageStart={-1}
								loadMore={this.loadItems}
								hasMore={this.state.sheets.hasmore}
								loader={<div className={style.loader}/>}>

								<div className={style.sheetWrapper}>
									{this.renderStuff()}
								</div>
							</InfiniteScroll>
						</div>
					</Container>
					<div className={style.blanker} />
				</div>
				<Footer />
			</React.Fragment>
		);
	}

	reloadStoreData=async()=>{
		let storeSelect;
		for(let i=0;i<this.state.data.storeList.length;i++) {
			let store = this.state.data.storeList[i];
			if(store.code === this.state.filter.store) {
				storeSelect = store;
				break;
			}
		}
		await this.setState({storeData: storeSelect})
	}
	
	genTermOptions = () => {
		const ops = [{label:'any',value:'any'},{label:'1',value:1},{label:'2',value:2},{label:'3',value:3}];
		return ops.map(({label,value}) => (
				<option label={label==='any'?'ทุกเทอม':label} value={value} key={value}>{label==='any'?'ทุกเทอม':label}</option>
			)
		);
	}
	
	genYearOptions = () => {
		var ops=[0];
		var curr = new Date().getFullYear();
		for(var i=0;i<10;i++)
			ops.push(curr-i);
		return ops.map((y) => (
				<option label={y===0?'ทุกปี':y} value={y} key={y}>{y===0?('ทุกปี'):y}</option>
			)
		);
	}
	
	genSortOptions = () => {
		const ops = [{label:'ราคาต่ำขึ้นก่อน',value:'price'},{label:'คะแนนมากขึ้นก่อน',value:'score'},{label:'คนซื้อมากขึ้นก่อน',value:'sold'},{label:'ล่าสุดขึ้นก่อน',value:'date'}];
		return ops.map(({label,value}) => (
				<option label={label} value={value} key={value}>{label}</option>
			)
		);
	}
	
	setFilter = async(field,val) => {
		var x=this.state.filter;
		x[field]=val;
		await this.setState({filter:x});
		this.reloadSheets();
	}

	scrolltoTop = () => {
		const scroll = this.ref.current.getParentElement(this.ref.current.scrollComponent)
		scroll.scrollTop = 0
	}

	reset() {
		if (this.ref && this.ref.current) {
			this.ref.current.pageLoaded = this.ref.current.props.pageStart

			this.scrolltoTop()
		}
	}
	
	reloadSheets = async() => {
		var time=(new Date()).getTime();
		this.reset();
		await this.setState({sheets:{
			page:0,
			loading:true,
			hasmore:true,
			disp:[],
			timeStamp:time,
		}});
	}
	
	genUniOptions = () => {
		if(this.state.data.storeList.length===0)
			return [{value: '0', name: ''}];
		return this.state.data.storeList.map(({name,short,code}) => (
				{value:code.toString(),name:name[0]}
			)
		);
	}
	
	genCatOptions = () => {
		const ops = [{label:'ไฟนอล',value:2},{label:'มิดเทอม',value:1},{label:'อื่นๆ',value:0},{label:'ทุกประเภท',value:-1}];
		return ops.map(({label,value}) => (
				<option label={label} value={value} key={value}>{label}</option>
			)
		);
	}
	
	renderStuff=()=>{
		if(this.state.sheets.disp.length===0&&!this.state.sheets.hasmore)
			return (<div className={style.nothing}>ไม่พบรายการ</div>);
		return this.state.sheets.disp.map((sheet) => {
				return (
					<SellSheet sheet={sheet} key={sheet._id} store={{}} addCart={this.addCart}>
					</SellSheet>
				)
			}
		);
	}
	
	addCart=async(id)=>{
		var data = await Util.sendPost('/api/public/ownSheet',{id});
		if(data.success) {
			var res = await Util.addCart(id);
			if(res)
				this.props.alert.show('เพิ่มชีทลงตะกร้าสำเร็จ',{type: 'success'});
			else
				this.props.alert.show('มีชีทนี้ในตะกร้าอยู่แล้ว',{type: 'error'});
		}
		else
			this.props.alert.show('คุณเป็นเจ้าของชีทนี้อยู่แล้ว',{type: 'error'});
	}
	
	loadItems = async(page) => {
		var sorter={
			price:'price',
			score:'-score',
			sold:'-bought',
			date:'-created',
		};
		var x = this.state.sheets;
		x.page = page;
		var data = await Util.sendPost('/api/public/searchSheet',{
			search:this.state.filter.search.toLowerCase(),
			filter:{
				store:this.state.filter.store,
				semester:this.state.filter.sem==='any'?undefined:this.state.filter.sem,
				year:this.state.filter.year==='0'?undefined:this.state.filter.year,
				category:this.state.filter.cat.toString()==='-1'?undefined:this.state.filter.cat,
			},
			sort:sorter[this.state.filter.sort],
			page:page - 1,
			pageLimit:20,
			timeStamp:this.state.sheets.timeStamp
		});
		while(this.state.pagemutex < page)
			await Util.sleep(10);
		await this.setState({pagemutex:page});
		x = this.state.sheets;
		if(data.success&&x.timeStamp===data.timeStamp)
		{
			if (data.data.length === 0)
				x.hasmore = false;
			var has = x.disp.map(h => h._id);
			for (var i = 0; i < data.data.length; i++)
				if (!has.includes(data.data[i]._id))
					x.disp.push(data.data[i]);
			this.setState({
				sheets: x
			});
		}

		await this.setState({pagemutex:1000000});
	}
}

export default withRouter(withAlert()(Sell));