import React from "react";
import style from "./Style.module.css";
import { Hidden, Visible } from "react-grid-system";

class SellSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Hidden xs>
          <div className={style.wrapper}>
            <img
              onClick={this.navigatora}
              className={style.cover}
              src={
                process.env.REACT_APP_SHEET_URL +
                this.props.sheet._id +
                "/cover.jpg"
              }
              alt="cover"
            ></img>
            <div onClick={this.navigatora} className={style.addCart}>
              รายละเอียดเพิ่มเติม
            </div>
            <div
              className={style.addCart}
              onClick={(event) => this.props.addCart(this.props.sheet._id)}
            >
              เพิ่มลงตะกร้า
            </div>
            <div
              className={style.removeFavorite}
              onClick={(event) =>
                this.props.remove(this.props.sheet._id, this.props.sheet.name)
              }
            >
              ลบจากรายการ
            </div>
          </div>
        </Hidden>
        <Visible xs>
          <div className={style.wrapperMobile}>
            <img
              onClick={this.navigatora}
              className={style.coverMobile}
              src={
                process.env.REACT_APP_SHEET_URL +
                this.props.sheet._id +
                "/cover.jpg"
              }
              alt="cover"
            ></img>
            <div>
              <div onClick={this.navigatora} className={style.infoMobile}>
                รายละเอียดเพิ่มเติม
              </div>
              <div
                className={style.addCartMobile}
                onClick={(event) => this.props.addCart(this.props.sheet._id)}
              >
                เพิ่มลงตะกร้า
              </div>
              <div
                className={style.removeFavoriteMobile}
                onClick={(event) =>
                  this.props.remove(this.props.sheet._id, this.props.sheet.name)
                }
              >
                ลบจากรายการ
              </div>
            </div>
          </div>
        </Visible>
      </React.Fragment>
    );
  }

  navigatora = (event) => {
    window.open("/infoSheet?" + this.props.sheet._id, "_blank");
  };

  scoreText = (score, reviewer) => {
    if (reviewer === 0) return "ไม่มีคะแนน";
    var real = score * 1.0;
    return real.toFixed(2);
  };

  dateMan = (date) => {
    var d = new Date(date);
    return (
      d.getDate().toString() +
      " / " +
      (d.getMonth() + 1).toString() +
      " / " +
      d.getFullYear()
    );
  };
}

export default SellSheet;
