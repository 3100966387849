import React from "react";
import Util from "../../Util";
import style from "./Style.module.css";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";

class Sell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    var id = this.props.location.search.substring(1);
    var data = await Util.sendPost("/api/user/readMySheet", { id });
    if (data.success) window.location.assign(data.data);
    else this.props.history.push("/");
  }

  render() {
    return (
      <div className={style.Page}>
        <div className={style.loader}></div>
        <div className={style.center}>
          หากค้างอยู่ที่หน้านี้นาน โปรดตรวจสอบแถบ Download ของ browser ของท่าน
          <br />
          โดยใน Safari จะอยู่ด้านขวาของ url bar เป็นลูกศรชี้ลง
          <br />
          ส่วนใน Chrome Mobile จะเด้งขึ้นมาด้านล่าง
        </div>
      </div>
    );
  }
}

export default withRouter(withAlert()(Sell));
