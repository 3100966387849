import React from "react";
import style from "./Style.module.css";
import { Hidden, Visible } from "react-grid-system";
import { Link } from "react-router-dom";

class SellSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Hidden xs>
          <div className={style.wrapper}>
            <img
              className={style.cover}
              src={
                process.env.REACT_APP_SHEET_URL +
                this.props.sheet._id +
                "/cover.jpg"
              }
              alt="cover"
            ></img>
            <Link
              to={"/user/readSheet?" + this.props.sheet._id}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={style.addCartAvail}>อ่านชีท</div>
            </Link>
            <div
              id={this.props.sheet._id + "review"}
              onClick={() => this.props.reviewHandler(this.props.sheet)}
              className={style.addCartAvail}
            >
              เพิ่ม / แก้ไข Comment
            </div>
            <div
              id={this.props.sheet._id}
              onClick={() => this.props.reportHandler(this.props.sheet)}
              className={style.report}
            >
              Report
            </div>
          </div>
        </Hidden>
        <Visible xs>
          <div className={style.wrapperMobile}>
            <img
              className={style.coverMobile}
              src={
                process.env.REACT_APP_SHEET_URL +
                this.props.sheet._id +
                "/cover.jpg"
              }
              alt="cover"
            ></img>
            <div>
              <Link
                to={"/user/readSheet?" + this.props.sheet._id}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={style.infoMobile}>อ่านชีท</div>
              </Link>
              <div
                id={this.props.sheet._id + "review"}
                onClick={() => this.props.reviewHandler(this.props.sheet)}
                className={style.reviewMobile}
              >
                เพิ่ม / แก้ไข Comment
              </div>
              <div
                id={this.props.sheet._id}
                onClick={() => this.props.reportHandler(this.props.sheet)}
                className={style.reportMobile}
              >
                Report
              </div>
            </div>
          </div>
        </Visible>
      </React.Fragment>
    );
  }
}

export default SellSheet;
