import React from "react";
import { Link } from "react-router-dom";
import Util from "../../Util";
import style from "./Style.module.css";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
import { Container } from "react-grid-system";

class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uniCode: "",
      driveCode: "",
      data: {
        uni: [],
        drive: [],
      },
    };
  }

  async componentDidMount() {
    var data = await Util.sendGet("/api/moresheetStaff/listUni");
    if (!data.success) {
      await alert(data.msg[0]);
      return this.props.history.push("/staff");
    }
    await this.setState({ data: data.data });
  }

  render() {
    return (
      <React.Fragment>
        <div className={style.sheetPage}>
          <Container>
            <div className={style.container}>
              <div className={style.blankTop} />
              <div className={style.header}>Staff Store Management</div>
              <hr />
              <div className={style.label}>Uni</div>
              <input
                placeholder="New Uni code"
                type="text"
                value={this.state.uniCode}
                onChange={(event) => {
                  this.setState({ uniCode: event.currentTarget.value });
                }}
              />
              <button
                onClick={() => {
                  this.addUni();
                }}
              >
                Add Uni
              </button>
              {this.displayUni(this.state.data.uni, "uni")}
              <div className={style.label}>Drive</div>
              <input
                placeholder="New Drive code"
                type="text"
                value={this.state.driveCode}
                onChange={(event) => {
                  this.setState({ driveCode: event.currentTarget.value });
                }}
              />
              <button
                onClick={() => {
                  this.addDrive();
                }}
              >
                Add Drive
              </button>
              {this.displayUni(this.state.data.drive, "drive")}
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  addUni = async () => {
    var data = await Util.sendPost("/api/moresheetStaff/newUni", {
      code: parseInt(this.state.uniCode),
    });
    if (!data.success) {
      await alert(data.msg[0]);
    } else window.location.reload();
  };
  addDrive = async () => {
    var data = await Util.sendPost("/api/moresheetStaff/newDrive", {
      code: parseInt(this.state.driveCode),
    });
    if (!data.success) {
      await alert(data.msg[0]);
    } else window.location.reload();
  };

  displayUni = (ss, ee) => {
    return ss.map((u) => {
      return (
        <Link
          to={"/staff/edit" + ee + "?code=" + u.code}
          target="_blank"
          rel="noreferer noopenner"
        >
          <div key={u._id}>
            {u.code}: {u.name[0]}
          </div>
        </Link>
      );
    });
  };
}

export default withRouter(withAlert()(EditSheet));
