import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Util from "../../Util";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import UnRegForm from "../../Components/UnRegForm";
import style from "./Style.module.css";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
import { Container, Visible, Hidden } from "react-grid-system";
import circleRight from "../../asset/Ellipse.svg";
import circleLeft from "../../asset/Ellipse2.svg";
import sell1 from "../../asset/sell1.svg";
import sell2 from "../../asset/sell2.svg";
import sell3 from "../../asset/sell3.svg";

class Sell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {
        user: {
          infoEnter: true,
        },
      },
      FAQ: [false, false, false],
    };
  }

  async componentDidMount() {
    if (window.location.search)
      localStorage.setItem("sellRef", window.location.search.substring(1));
    await this.setState({ loading: true });
    var data = await Util.sendPost("/api/public/getSellerHome", {});
    await this.setState({ data: data.data });
    if (data.data.seller) this.props.history.push("/seller/mysheet");
    await this.setState({ loading: false });
  }

  async componentWillReceiveProps(props) {
    if (window.location.search)
      localStorage.setItem("sellRef", window.location.search.substring(1));
    await this.setState({ loading: true });
    var data = await Util.sendPost("/api/public/getSellerHome", {});
    await this.setState({ data: data.data });
    if (data.data.seller) this.props.history.push("/seller/mysheet");
    await this.setState({ loading: false });
  }

  render() {
    if (this.state.loading)
      return (
        <React.Fragment>
          <div className={style.shopPage}></div>
          <Footer />
        </React.Fragment>
      );
    return (
      <React.Fragment>
        <div className={style.shopPage}>
          <Nav current="seller" user={this.state.data.user} />
          <Hidden xs sm>
            <img className={style.circleRight} alt="" src={circleRight} />
            <img className={style.circleLeft} alt="" src={circleLeft} />
          </Hidden>
          <Container>
            <Hidden xs sm>
              <div className={style.topSpace} />
              <div className={style.header}>ขายชีท</div>
              <div className={style.smallHead}>
                สนใจอยากแบ่งปันความรู้? สมัครเป็นนักทำชีทสรุป <br />
                และเริ่มขายชีทของคุณที่ moresheet.co!
              </div>
              <div className={style.line} />
              <div className={style.headerMore}>ทำไมต้อง moresheet</div>
              <div className={style.sell1Con}>
                <Fade top>
                  <div className={style.sellBox}>
                    <img className={style.sellNumImage} alt="" src={sell1} />
                    <div className={style.boxHead}>สะสมรายได้</div>
                    <div className={style.boxTent}>
                      สร้างรายได้เสริม ในระหว่างการศึกษา
                    </div>
                  </div>
                </Fade>
              </div>
              <div className={style.sell2Con}>
                <Fade top delay={100}>
                  <div className={style.sellBox}>
                    <img className={style.sellNumImage} alt="" src={sell2} />
                    <div className={style.boxHead}>แบ่งปันความรู้</div>
                    <div className={style.boxTent}>
                      เพื่อน ๆ นักศึกษาหลายพันคน กำลังต้องการความรู้ของคุณ
                    </div>
                  </div>
                </Fade>
              </div>
              <div className={style.sell3Con}>
                <Fade top delay={200}>
                  <div className={style.sellBox}>
                    <img className={style.sellNumImage} alt="" src={sell3} />
                    <div className={style.boxHead}>ทบทวน</div>
                    <div className={style.boxTent}>
                      ได้ทวนเนื้อหาอีกรอบ ในระหว่างการทำชีทสรุป
                    </div>
                  </div>
                </Fade>
              </div>
              <div className={style.headerFAQ}>คำถามที่พบบ่อย</div>
              <Link to="/faq">
                <div className={style.faq}>คลิกเพื่ออ่าน</div>
              </Link>
              {this.state.data.user ? (
                <UnRegForm register={this.register} />
              ) : (
                <Link to="/login">
                  <div className={style.goLogin}>
                    เข้าสู่ระบบก่อนสมัครเป็นผู้ขายชีท
                  </div>
                </Link>
              )}
              <div className={style.bottomBlank} />
            </Hidden>

            <Visible xs sm>
              <div className={style.topSpace} />
              <div className={style.header + " " + style.mobile}>ขายชีท</div>
              <div className={style.smallHead + " " + style.mobile}>
                สนใจอยากแบ่งปันความรู้? สมัครเป็นนักทำชีทสรุป <br />
                และเริ่มขายชีทของคุณที่ moresheet.co!
              </div>
              <div className={style.line + " " + style.mobile} />
              <div className={style.headerMore + " " + style.mobile}>
                ทำไมต้อง moresheet
              </div>
              <div className={style.sell1Con + " " + style.mobile}>
                <Fade top>
                  <div className={style.sellBox}>
                    <img
                      className={style.sellNumImage + " " + style.mobile}
                      alt=""
                      src={sell1}
                    />
                    <div className={style.boxHead + " " + style.mobile}>
                      สะสมรายได้
                    </div>
                    <div className={style.boxTent + " " + style.mobile}>
                      สร้างรายได้เสริม ในระหว่างการศึกษา
                    </div>
                  </div>
                </Fade>
              </div>
              <div className={style.sell2Con + " " + style.mobile}>
                <Fade top delay={100}>
                  <div className={style.sellBox}>
                    <img
                      className={style.sellNumImage + " " + style.mobile}
                      alt=""
                      src={sell2}
                    />
                    <div className={style.boxHead + " " + style.mobile}>
                      แบ่งปันความรู้
                    </div>
                    <div className={style.boxTent + " " + style.mobile}>
                      เพื่อน ๆ นักศึกษาหลายพันคน กำลังต้องการความรู้ของคุณ
                    </div>
                  </div>
                </Fade>
              </div>
              <div className={style.sell3Con + " " + style.mobile}>
                <Fade top delay={200}>
                  <div className={style.sellBox}>
                    <img
                      className={style.sellNumImage + " " + style.mobile}
                      alt=""
                      src={sell3}
                    />
                    <div className={style.boxHead + " " + style.mobile}>
                      ทบทวน
                    </div>
                    <div className={style.boxTent + " " + style.mobile}>
                      ได้ทวนเนื้อหาอีกรอบ ในระหว่างการทำชีทสรุป
                    </div>
                  </div>
                </Fade>
              </div>
              <div className={style.headerFAQ + " " + style.mobile}>
                คำถามที่พบบ่อย
              </div>
              <Link to="/faq">
                <div className={style.faq}>คลิกเพื่ออ่าน</div>
              </Link>
              {this.state.data.user ? (
                <UnRegForm register={this.register} />
              ) : (
                <Link to="/login">
                  <div className={style.goLogin}>
                    เข้าสู่ระบบก่อนสมัครเป็นผู้ขายชีท
                  </div>
                </Link>
              )}
              <div className={style.bottomBlank} />
            </Visible>
          </Container>
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  toggleFAQ = (num) => {
    var x = this.state.FAQ;
    x[num] = !x[num];
    this.setState({ FAQ: x });
  };

  register = async (data) => {
    data.bankInfo = data.bankName.trim() + "," + data.bankNumber.trim();
    data.sellRef = localStorage.getItem("sellRef");
    var data2 = await Util.sendPost("/api/user/registerSeller", data);
    if (data2.success) {
      this.props.alert.show("สำเร็จ กำลังพาคุณไปสู่หน้าขายชีท", {
        type: "success",
        timeout: 1000,
      });
      setTimeout(() => {
        this.props.history.push("/seller/mysheet");
      }, 1000);
    } else
      this.props.alert.show(data2.msg[0], { type: "error", timeout: 1000 });
  };
}

export default withRouter(withAlert()(Sell));
